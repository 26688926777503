import client from "../../client/client";

class MyService {
  getDetails(cancelToken) {
    return client.get("/users/me", { config: { cancelToken } });
  }
  getProjects(
    { page = 0, limit = 50, sort = "created", direction },
    { name, isUserActivated = true, isAccessibleByLoggedInCompany = true, isArchiveEnabled = true },
    cancelToken
  ) {
    return client.post("/users/me/projects", {
      params: { page, limit, sort, direction },
      payload: { name, isUserActivated, isAccessibleByLoggedInCompany, isArchiveEnabled },
      config: { cancelToken },
    });
  }
  changeLanguage({ value }, cancelToken) {
    return client.post("/users/me/language", { payload: { value }, config: { cancelToken } });
  }
  getPreferedLanguage(cancelToken) {
    return client.get("/users/me/language", { config: { cancelToken } });
  }
}

export default new MyService();
