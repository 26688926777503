import client from "../client/client";

class SimilarContentService {
  getMostRelevantChaptersContents({ page = 0, limit = 50 }, { input, isInChapter, isInContent }, cancelToken) {
    return client.post("/searchcard/projects/deliverable/search", {
      payload: { input, isInChapter, isInContent },
      params: { page, limit },
      config: { cancelToken },
    });
  }
}
export default new SimilarContentService();
