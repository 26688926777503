import React, { useEffect, useMemo, useRef, useState } from "react";
import { CustomIconButton, DropDown, MenuElement, icon } from "../../../../../../../common/components";
import { MenuList } from "@mui/material";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import styles from "./PropositionSelection.module.css";
import { translate } from "../../../../../../../common/providers";
import { useSelector } from "react-redux";
const PropositionSelection = ({ onDirectAskQuestion }) => {
  const editContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.editContent);
  const currentEditContentIndex = useSelector(({ multiProjectSearch }) => multiProjectSearch.currentEditContentIndex);
  const [menuOpened, setMenuOpened] = useState(false);
  const [openedSubMenu, setOpenedSubMenu] = useState("");
  const anchorRef = useRef();
  useEffect(() => {
    if (menuOpened) {
      setOpenedSubMenu("");
    }
  }, [menuOpened]);
  const handleCloseMenu = () => {
    setMenuOpened(false);
  };
  const handleCloseSubMenu = () => {
    setOpenedSubMenu("");
  };
  const handleClose = () => {
    handleCloseSubMenu();
    handleCloseMenu();
  };
  const handleDirectAskQuestion = (question) => {
    handleClose();
    onDirectAskQuestion(translate(question));
  };
  const menus = useMemo(
    () => [
      {
        value: "summarize",
        title: translate("multi-projects-search.edition.proposition-selection.summarize.title"),
        action: () => handleOpenMenu("summarize"),
        subMenus: [
          {
            title: translate("multi-projects-search.edition.proposition-selection.reduce-by-half.title"),
            action: () =>
              handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.reduce-by-half.value"),
          },
          {
            title: translate("multi-projects-search.edition.proposition-selection.short-summary.title"),
            action: () =>
              handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.short-summary.value"),
          },
        ],
      },
      {
        value: "change-time",
        title: translate("multi-projects-search.edition.proposition-selection.change-time.title"),
        action: () => handleOpenMenu("change-time"),
        subMenus: [
          {
            title: translate("multi-projects-search.edition.proposition-selection.present.title"),
            action: () => handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.present.value"),
          },
          {
            title: translate("multi-projects-search.edition.proposition-selection.future.title"),
            action: () => handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.future.value"),
          },
        ],
      },
      {
        title: translate("multi-projects-search.edition.proposition-selection.correct-spelling.title"),
        action: () =>
          handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.correct-spelling.value"),
      },
      {
        title: translate("multi-projects-search.edition.proposition-selection.correcting-syntax.title"),
        action: () =>
          handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.correcting-syntax.value"),
      },
      {
        title: translate("multi-projects-search.edition.proposition-selection.list-synonims.title"),
        action: () =>
          handleDirectAskQuestion("multi-projects-search.edition.proposition-selection.list-synonims.value"),
      },
    ],
    []
  );
  const handleMenuExpand = () => {
    setMenuOpened(true);
  };
  const handleOpenMenu = (subMenu) => {
    setOpenedSubMenu(subMenu);
  };

  return (
    <>
      <CustomIconButton
        ref={anchorRef}
        color="secondary"
        disabled={!editContent?.[currentEditContentIndex]}
        icon={icon.faMagicWandSparkles}
        size="sm"
        variant="outlined"
        onClick={handleMenuExpand}
      />
      <DropDown ref={anchorRef} open={menuOpened} onClose={handleClose}>
        <MenuList className={styles.menuContainer}>
          {isNonEmptyArray(menus) &&
            menus
              .filter((menu) => !menu.hidden)
              .map((menu) => (
                <MenuElement
                  key={menu.title}
                  menu={menu}
                  openedSubMenu={openedSubMenu}
                  onCloseSubMenu={handleCloseSubMenu}
                />
              ))}
        </MenuList>
      </DropDown>
    </>
  );
};

export default PropositionSelection;
