import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomIconButton,
  DocPreview,
  InfiniteList2,
  createNotification,
  generateFilters,
  icon,
} from "../../../../../../../common/components";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService, SearchCardService, SimilarContentService } from "../../../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import { IdentifiedContentBox } from "../../../..";
import {
  addNewSavedContent,
  addNewSavedProjectContent,
  getPage,
  resetContent,
} from "../../../../../slice/multi-project-search-slice";
import styles from "./IdentifiedContents.module.css";
import { SEARCH_TABS } from "../../../../../constants/tabs";
import { isNonEmptyArray } from "../../../../../../../common/utils";

const IdentifiedContents = () => {
  const { call: getMostRelevantChaptersContents } = useApi(SimilarContentService.getMostRelevantChaptersContents);
  const { call: getMostRelevantContentInProject } = useApi(SearchCardService.search);
  const { call: onGetSelectedContentFromContentIds } = useApi(ContentService.getSelectedContentFromContentIds);
  const filters = useSelector(({ multiProjectSearch }) => multiProjectSearch.filters);
  const projectFilters = useSelector(({ multiProjectSearch }) => multiProjectSearch.projectFilters);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [chaptersRequest, setChaptersRequest] = useState(null);
  const [projectRequest, setProjectRequest] = useState(null);
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (filters.content) {
      setChaptersRequest(
        () =>
          ({ page, limit }) =>
            getMostRelevantChaptersContents(
              { page, limit },
              {
                input: filters.content,
                isInChapter: filters.granularity?.includes("chapter") ? true : undefined,
                isInContent: filters.granularity?.includes("content") ? true : undefined,
              }
            )
      );
    }
  }, [filters]);
  useEffect(() => {
    if (Object.keys(projectFilters).some((item) => item !== "Type" && item !== "separator")) {
      setProjectRequest(
        () =>
          ({ page, limit }) =>
            getMostRelevantContentInProject({ projectId, page, limit }, generateFilters(projectFilters))
      );
    }
  }, [projectFilters, projectId]);
  const handleCloseDisplay = () => setPreview(null);
  const handleDisplay = ({ projectId, documentId, informationId, previewResponse, identifiedContent }) => {
    setPreview({
      coordinates: previewResponse.rectangles,
      projectId: projectId,
      documentId,
      page: previewResponse.pageStart,
      numOfPages: previewResponse.numberOfPages,
      identifiedContent,
      informationId,
    });
  };
  const handleSaveContentFromList = ({ projectId, documentId, informationId, previewResponse, identifiedContent }) => {
    const { content, docUploadedDate, documentName, projectCreationDate, projectName } = identifiedContent;
    const { numberOfPages, pageStart, rectangles } = previewResponse;
    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      dispatch(
        addNewSavedContent({
          informationId,
          coordinates: rectangles,
          content,
          docUploadedDate,
          documentId,
          documentName,
          projectCreationDate,
          projectId,
          projectName,
          numOfPages: numberOfPages,
          page: pageStart,
        })
      );
    } else {
      dispatch(
        addNewSavedProjectContent({
          projectId,
          content: {
            informationId,
            coordinates: rectangles,
            content,
            docUploadedDate,
            documentId,
            documentName,
            projectCreationDate,
            projectId,
            projectName,
            numOfPages: numberOfPages,
            page: pageStart,
          },
        })
      );
    }
    createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
  };
  const handleSaveContent = ({ coordinates, informationId, recievedRectangles, content }) => {
    const { docUploadedDate, documentId, documentName, projectCreationDate, projectId, projectName } =
      preview?.identifiedContent;
    const { numOfPages, page } = preview;
    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      dispatch(
        addNewSavedContent({
          informationId,
          coordinates,
          recievedRectangles,
          content,
          docUploadedDate,
          documentId,
          documentName,
          projectCreationDate,
          projectId,
          projectName,
          numOfPages,
          page,
        })
      );
    } else {
      dispatch(
        addNewSavedProjectContent({
          projectId,
          content: {
            informationId,
            coordinates,
            recievedRectangles,
            content,
            docUploadedDate,
            documentId,
            documentName,
            projectCreationDate,
            projectId,
            projectName,
            numOfPages,
            page,
          },
        })
      );
    }
    createNotification({ type: "info", message: translate("common:notification.selection-was-saved") });
  };

  const handleInitiateSaveContent = ({ coordinates, informationId, recievedRectangles }) => {
    const { content, documentId } = preview?.identifiedContent;
    if (!isNonEmptyArray(recievedRectangles)) {
      handleSaveContent({ coordinates, informationId, recievedRectangles, content });
      return;
    }
    let contentIds = [];
    recievedRectangles.forEach((recievedRectangle) => {
      if (isNonEmptyArray(recievedRectangle?.rectangles)) {
        recievedRectangle.rectangles.forEach((rec) => {
          if (!contentIds.some((cId) => rec.contentId === cId)) contentIds = [...contentIds, rec.contentId];
        });
      }
    });
    if (isNonEmptyArray(contentIds)) {
      onGetSelectedContentFromContentIds({ docId: documentId }, { contentIds })
        .then((data) => {
          handleSaveContent({
            coordinates,
            informationId,
            recievedRectangles,
            content: data.value.substring(0, 1000).replaceAll("\\", "\n"),
          });
        })
        .catch(console.error);
    }
  };

  const rowRenderer = (identifiedContent) => {
    return (
      <IdentifiedContentBox
        key={identifiedContent.informationId}
        identifiedContent={identifiedContent}
        onDisplay={handleDisplay}
        onSaveContent={handleSaveContentFromList}
      />
    );
  };
  const innerActions = ({ disableActions, renderCondition, onSaveContent, onInsert, onResetRecievedRectangles }) => (
    <>
      <CustomButton
        disabled={disableActions}
        size="md"
        startIcon={icon.faArrowLeft}
        variant="contained"
        onClick={onInsert}
      >
        {renderCondition
          ? translate("common:doc-preview.insert-selection")
          : translate("common:doc-preview.insert-chapter")}
      </CustomButton>
      <CustomIconButton
        disabled={disableActions}
        icon={icon.faBookmark}
        size="sm"
        tooltip={translate("common:btn.save")}
        variant="outlined"
        onClick={onSaveContent}
      />
      {renderCondition && (
        <CustomIconButton
          icon={icon.faBan}
          size="sm"
          tooltip={translate("common:doc-preview.cancel-selection")}
          variant="outlined"
          onClick={onResetRecievedRectangles}
        />
      )}
    </>
  );

  return (
    <div className={styles.container}>
      <InfiniteList2
        className={styles.list__container}
        noRowsText={translate("common:empty-state.no-results")}
        request={selectedSearchTab === SEARCH_TABS.PROJECT ? projectRequest : chaptersRequest}
        resetData={selectedSearchTab}
        rowRenderer={rowRenderer}
      />
      <DocPreview
        coordinates={preview?.coordinates}
        docId={preview?.documentId}
        infoId={preview?.informationId}
        innerActions={innerActions}
        numOfPages={preview?.numOfPages}
        open={!!preview}
        page={preview?.page}
        projectId={preview?.projectId}
        slice="multiProjectSearch"
        onClose={handleCloseDisplay}
        onGetPage={getPage}
        onResetContent={resetContent}
        onSaveContent={handleInitiateSaveContent}
      />
    </div>
  );
};

export default IdentifiedContents;
