class NavUtils {
  gotoHome(manualModification) {
    if (manualModification) {
      return "/?manualModification=true";
    }
    return "/";
  }
  gotoCompanySelection(manualModification) {
    if (manualModification) {
      return "/company-selection/?manualModification=true";
    }
    return "/company-selection";
  }
  gotoMainMenu() {
    return "/main-menu";
  }
  gotoMultiProjectSearch() {
    return "/multi-project-search";
  }
}
export default new NavUtils();
