import React, { useEffect, useState } from "react";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../common/providers";
import {
  CommonFilters,
  DocumentFilters,
  FiltersWrapper,
  InformationIdFilter,
  KeywordFilter,
  ProjectCategoriesFilter,
  ProjectFilters,
  adjustFiltersFromPayload,
  generateFilters,
} from "../../../../common/components";
import styles from "./Search.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setFilters, setProjectFilters } from "../../slice/multi-project-search-slice";
import { MyService, RequirementMatrixService, SearchCardService, SimilarContentService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { SEARCH_TABS } from "../../constants/tabs";
import { SearchHelpTooltip } from "../";

const granularityValues = ["chapter", "content"];

const Search = ({ onSetDelivFiltersLoaded, onSetProjectFiltersLoaded }) => {
  const { call: getParametersForInformation } = useApi(RequirementMatrixService.getParametersForInformation);
  const { call: countResults } = useApi(SearchCardService.countResults);
  const { call: getProjectTypes } = useApi(MyService.getProjectTypes);
  const { call: getProjectFilters } = useApi(SearchCardService.getProjectFilters);
  const { call: getDeliverablesFilters } = useApi(SimilarContentService.getDeliverablesFilters);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const filters = useSelector(({ multiProjectSearch }) => multiProjectSearch.filters);
  const projectFilters = useSelector(({ multiProjectSearch }) => multiProjectSearch.projectFilters);
  const projectId = useSelector(({ context }) => context.project?.id);
  useSelector(({ context }) => context.segFlags);
  const [parameters, setParameters] = useState({});
  const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
  const [projectTypesRequest, setProjectTypesRequest] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)) {
      getParametersForInformation()
        .then(setParameters)
        .catch((err) => {
          console.error(err);
        });
    }
  }, [getParametersForInformation, projectId]);
  useEffect(() => {
    if (projectId) {
      setFiltersPreviewRequest(() => (previewFilters) => {
        const newFilters = generateFilters(previewFilters);
        return countResults({ projectId }, newFilters).then((data) => data);
      });
    }
  }, [projectId]);

  useEffect(() => {
    setProjectTypesRequest(() => () => {
      return getProjectTypes({ onlyMine: true });
    });
  }, []);

  useEffect(() => {
    if (projectId) {
      onSetProjectFiltersLoaded(false);
      getProjectFilters({ projectId })
        .then((data) => {
          dispatch(setProjectFilters(adjustFiltersFromPayload(data.request)));
        })
        .catch(console.error)
        .finally(() => onSetProjectFiltersLoaded(true));
    }
  }, [getProjectFilters, projectId]);

  useEffect(() => {
    onSetDelivFiltersLoaded(false);
    getDeliverablesFilters()
      .then((data) => {
        const adjustedFilters = adjustFiltersFromPayload(data.request);
        dispatch(setFilters({ ...adjustedFilters, content: adjustedFilters.input, input: undefined }));
      })
      .catch(console.error)
      .finally(() => onSetDelivFiltersLoaded(true));
  }, [getDeliverablesFilters, projectId]);

  const handleApplyProjectFilters = (payload) => {
    dispatch(setProjectFilters(payload));
  };
  const handleApplyFilters = (payload) => {
    dispatch(setFilters(payload));
  };
  const customLabelApply = (results) => {
    if (results <= 1 && results !== null) {
      return translate("common:component.filter.apply", { results });
    }
    if (results > 1) {
      return translate("common:component.filters.apply", { results });
    }
    return translate("common:btn.apply");
  };

  return (
    <div className={styles.container}>
      {selectedSearchTab === SEARCH_TABS.PROJECT ? (
        <FiltersWrapper
          collapsableFilters
          hasLargeSearch
          hasSeparator
          multiline
          addFilterButtonVariant="text"
          className={styles.filter}
          components={[
            {
              enabled: true,
              component: CommonFilters.KEYWORDS,
              renderer: <KeywordFilter />,
            },
            {
              enabled: true,
              withDescription: false,
              multiSelection: true,
              labelKey: "label",
              valueKey: "key",
              component: CommonFilters.REQUIREMENT,
              disabled: !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
            },
            { enabled: true, component: CommonFilters.INFORMATION_ID, renderer: <InformationIdFilter /> },
            {
              enabled: true,
              component: CommonFilters.DOCUMENTS,
              renderer: <DocumentFilters />,
              withDescription: false,
              disabled: !isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
            },
            {
              enabled: true,
              component: CommonFilters.TOC,
              renderer: <KeywordFilter />,
              disabled: !isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
            },
            {
              enabled: true,
              component: CommonFilters.CATEGORIES,
              renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
              hasFavorites: true,
              disabled: !isSegFeatureEnabled(SegFlags.CATEGORY),
            },
            {
              enabled: true,
              component: CommonFilters.CLIENT_REF,
              disabled: !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
            },
            {
              enabled: true,
              component: CommonFilters.CRITICALITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.criticality?.map((c) => ({
                label: `common:enum.params.criticality.${c.toLowerCase()}`,
                value: c,
              })),
              disabled: !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
            },
            {
              enabled: true,
              component: CommonFilters.NEGOTIABILITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.negotiability?.map((n) => ({
                label: `common:enum.params.negotiability.${n.toLowerCase()}`,
                value: n,
              })),
              disabled: !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
            },
            {
              enabled: true,
              component: CommonFilters.TYPE,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.type?.map((t) => ({
                label: `common:enum.params.type.${t.toLowerCase()}`,
                value: t,
              })),
              disabled: !isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
            },
          ]}
          customLabelApply={customLabelApply}
          defaultFilters={projectFilters}
          disablePortal={false}
          filterPopUpPlacement="auto"
          helpTooltip={<SearchHelpTooltip />}
          previewRequest={filtersPreviewRequest}
          searchPlaceHolder={translate("multi-projects-search.search.filter.search-by-keyword")}
          onApply={handleApplyProjectFilters}
        />
      ) : (
        <FiltersWrapper
          collapsableFilters
          hasLargeSearch
          hasSeparator
          multiline
          addFilterButtonVariant="text"
          className={styles.filter}
          components={[
            {
              enabled: true,
              component: CommonFilters.KEYWORDS,
              renderer: <KeywordFilter />,
            },
            {
              enabled: true,
              component: CommonFilters.GRANULARITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: granularityValues?.map((t) => ({
                label: `multi-projects-search.search.filter.${t.toLowerCase()}`,
                value: t,
              })),
            },
            { enabled: true, component: CommonFilters.PROJECT_TYPE, dynamicRequest: projectTypesRequest },
            { enabled: true, component: CommonFilters.PROJECT, renderer: <ProjectFilters /> },
          ]}
          defaultFilters={filters}
          helpTooltip={<SearchHelpTooltip />}
          searchPlaceHolder={translate("multi-projects-search.search.filter.search-by-keyword")}
          onApply={handleApplyFilters}
        />
      )}
    </div>
  );
};

export default Search;
