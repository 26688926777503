import React, { useCallback, useEffect, useState } from "react";
import { CircularLoader, CustomButton, CustomIconButton, icon } from "../../../../../common/components";
import styles from "./Edition.module.css";
import { translate } from "../../../../../common/providers";
import CustomTextField from "../../../../../common/components/text-fields/text-field/CustomTextField";
import { WordUtils } from "../../../../../common/utils";
import {
  addEditContent,
  decrementEditContentIndex,
  incrementEditContentIndex,
  setInitialEditContent,
} from "../../../slice/multi-project-search-slice";
import { useDispatch, useSelector } from "react-redux";
import { ContentService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import { Skeleton } from "@mui/material";
import { PropositionSelection } from "./components";

const newLineRegex = /[\f\r]/g;
// eslint-disable-next-line no-control-regex
const spaceRegex = /[\u000b\r]/g;

const Edition = () => {
  const { call: askQuestion } = useApi(ContentService.askQuestion);
  const editContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.editContent);
  const currentEditContentIndex = useSelector(({ multiProjectSearch }) => multiProjectSearch.currentEditContentIndex);
  const [question, setQuestion] = useState("");
  const [directQuestion, setDirectQuestion] = useState("");
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const dispatch = useDispatch();
  const handleAskQuestion = useCallback(() => {
    if (question && editContent?.[currentEditContentIndex]) {
      setLoadingAnswer(true);
      askQuestion({ question, content: editContent[currentEditContentIndex] })
        .then((data) => dispatch(addEditContent(data.value)))
        .catch(console.error)
        .finally(() => setLoadingAnswer(false));
    }
  }, [question, editContent, currentEditContentIndex]);
  useEffect(() => {
    if (directQuestion && editContent?.[currentEditContentIndex]) {
      setLoadingAnswer(true);
      askQuestion({ question: directQuestion, content: editContent[currentEditContentIndex] })
        .then((data) => dispatch(addEditContent(data.value)))
        .catch(console.error)
        .finally(() => setLoadingAnswer(false));
      setDirectQuestion("");
    }
  }, [directQuestion, editContent, currentEditContentIndex]);
  useEffect(() => {
    const handleKeyDetect = (e) => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        handleAskQuestion();
      }
    };
    window.addEventListener("keydown", handleKeyDetect, true);
    return () => {
      window.removeEventListener("keydown", handleKeyDetect, true);
    };
  }, [handleAskQuestion]);
  const handleSelectionCallBack = (selectedText) => {
    dispatch(setInitialEditContent(selectedText.value.replace(newLineRegex, "\n").replace(spaceRegex, " ")));
  };
  const handleInsertSelection = () => {
    WordUtils.insertSelectionToPlugin(handleSelectionCallBack);
  };
  const handleIncrement = () => {
    dispatch(incrementEditContentIndex());
  };
  const handleDecrement = () => {
    dispatch(decrementEditContentIndex());
  };
  const handleChangeQuestion = (e) => {
    setQuestion(e.target.value);
  };

  const handleDirectAskQuestion = (newDirectQuestion) => {
    setDirectQuestion(newDirectQuestion);
  };
  const handleInsertToDoc = () => {
    WordUtils.insertTextToWord(editContent?.[currentEditContentIndex]);
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__leftSide}>
          {editContent?.length > 1 && (
            <>
              <CustomIconButton
                disabled={currentEditContentIndex <= 0}
                icon={icon.faChevronLeft}
                size="sm"
                variant="outlined"
                onClick={handleDecrement}
              />
              <span>
                {currentEditContentIndex + 1}/{editContent?.length}
              </span>
              <CustomIconButton
                disabled={currentEditContentIndex >= editContent.length - 1}
                icon={icon.faChevronRight}
                size="sm"
                variant="outlined"
                onClick={handleIncrement}
              />
            </>
          )}
        </div>
        <div className={styles.header__rightSide}>
          <CustomButton color="secondary" startIcon={icon.faPlus} variant="outlined" onClick={handleInsertSelection}>
            {translate("common:doc-preview.insert-selection")}
          </CustomButton>
          <CustomButton startIcon={icon.faArrowLeft} variant="contained" onClick={handleInsertToDoc}>
            {translate("common:btn.insert")}
          </CustomButton>
        </div>
      </div>
      <div className={styles.information} data-is-loading={loadingAnswer}>
        {loadingAnswer ? <CircularLoader /> : editContent?.[currentEditContentIndex]}
      </div>
      <div className={styles.search}>
        {loadingAnswer ? (
          <Skeleton className={styles.skeleton} sx={{ fontSize: "2rem" }} />
        ) : (
          <CustomTextField
            fullWidth
            placeHolder={"common:textfield.text.ask"}
            size="sm"
            value={question}
            onChange={handleChangeQuestion}
          />
        )}
        <PropositionSelection onDirectAskQuestion={handleDirectAskQuestion} />
        <CustomButton
          disabled={!question || !editContent?.[currentEditContentIndex]}
          variant="contained"
          onClick={handleAskQuestion}
        >
          {translate("common:btn.validate")}
        </CustomButton>
      </div>
    </div>
  );
};

export default Edition;
