import { MenuItem } from "@mui/material";
import React, { useRef } from "react";
import styles from "./MenuElement.module.css";
import SubMenus from "./SubMenus";
import { VerticalDivider, IconComponent, icon } from "..";
import { isNonEmptyArray } from "../../utils";
const MenuElement = ({ menu, onCloseSubMenu, openedSubMenu }) => {
  const anchorRef = useRef();
  return (
    <MenuItem key={menu.title} ref={anchorRef} className={styles.menuItem} onClick={menu.action}>
      {menu.title}
      {isNonEmptyArray(menu.subMenus) && (
        <div className={styles.expandContainer}>
          <VerticalDivider inFlexContainer />
          <IconComponent color="var(--color-light-grey-1)" icon={icon.faChevronRight} />
          <SubMenus
            ref={anchorRef}
            open={openedSubMenu === menu.value}
            subMenus={menu.subMenus}
            onClose={onCloseSubMenu}
          />
        </div>
      )}
    </MenuItem>
  );
};

export default MenuElement;
