import client from "../../client/client";

class ContentService {
  getContentCoordinatesFromInformation({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/information/${informationId}`, { config: { cancelToken } });
  }
  getChapterString({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/chapter/information/${informationId}/contents`, {
      config: { cancelToken },
    });
  }
  getPreviewInformationChapter({ docId, informationId }, cancelToken) {
    return client.get(`/contents/documents/${docId}/position/information/${informationId}`, {
      config: { cancelToken },
    });
  }
  getContentsInSelection({ docId }, { pagePosition }, cancelToken) {
    return client.post(`/contents/documents/${docId}/contents-position`, {
      payload: { pagePosition },
      config: { cancelToken },
    });
  }
  getSelectedContentFromContentIds({ docId }, { contentIds }, cancelToken) {
    return client.post(`/contents/documents/${docId}/selected`, {
      payload: { values: contentIds },
      config: { cancelToken },
    });
  }
  getContentSummary({ informationIds, contentIds }, cancelToken) {
    return client.post("/spp/ai/summary", {
      payload: { informationIds, contentIds },
      config: { cancelToken },
    });
  }
  getGenerateChapter(
    { deliverableInformationIds, deliverableContentIds, tenderInformationIds, tenderContentIds },
    cancelToken
  ) {
    return client.post("/spp/ai/req-res-match", {
      payload: { deliverableInformationIds, deliverableContentIds, tenderInformationIds, tenderContentIds },
      config: { cancelToken },
    });
  }
  askQuestion({ question, content }, cancelToken) {
    return client.post("/spp/ai/chat", {
      payload: { question, content },
      config: { cancelToken },
    });
  }
}
export default new ContentService();
