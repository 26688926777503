import React, { useRef, useState } from "react";
import { MenuItem, MenuList } from "@mui/material";
import { CustomButton, icon, DropDown, CustomTooltip } from "../../..";
import styles from "./FilterSelector.module.css";
import { isNonEmptyArray } from "../../../../utils";
import { OPERATORS } from "../../../../constants";
import { translate } from "../../../../providers";

const FilterSelector = ({
  disableOr,
  itemDisabledClassName,
  itemClassName,
  itemRenderer,
  items,
  onChange,
  selectionRenderer,
  size = "sm",
  value,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (selection) => {
    onChange(selection);
    handleClose();
  };
  const handleSelection = () => {
    if (value) {
      if (typeof selectionRenderer === "function") {
        return selectionRenderer(value);
      }
      return itemRenderer(value);
    }
    return "";
  };
  return (
    <>
      <CustomButton
        ref={anchorRef}
        className={styles.button}
        color="secondary"
        data-size={size}
        data-testid="position.partner.btn"
        endIcon={icon.faChevronDown}
        variant="outlined"
        onClick={handleOpen}
      >
        {handleSelection()}
      </CustomButton>
      <DropDown ref={anchorRef} open={open} onClose={handleClose}>
        <MenuList className={styles.dropDown__container}>
          {isNonEmptyArray(items) &&
            items.map((item) => (
              <CustomTooltip
                key={item?.id || item}
                arrow
                placement="right"
                title={
                  item === OPERATORS.OR && disableOr
                    ? translate("common:separator.or.tooltip.disabled-when-negative-keyword-filters")
                    : ""
                }
              >
                <div>
                  <MenuItem
                    className={`${styles.dropDown__item} ${itemClassName} ${
                      ((item === OPERATORS.OR && disableOr) || value === item) && itemDisabledClassName
                    }`}
                    onClick={() => handleSelect(item)}
                  >
                    {itemRenderer(item)}
                  </MenuItem>
                </div>
              </CustomTooltip>
            ))}
        </MenuList>
      </DropDown>
    </>
  );
};

export default FilterSelector;
