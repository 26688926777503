import React, { useEffect, useState } from "react";
import { NavUtils, exportView } from "../../navigation";
import { UserService } from "../../api";
import { useApi } from "../../common/hooks";
import { CustomButton } from "../../common/components";
import styles from "./CompanySelection.module.css";
import { useDispatch, useSelector } from "react-redux";
import { KeycloakService } from "../../sevices";
import { useHistory } from "react-router-dom";
import { setCompanies, setCompany, setDomain, setToken } from "../../common/slice";
import CompanyRow from "./common/CompanyRow";
import { isNonEmptyArray, parseJwt } from "../../common/utils";
import { translate } from "../../common/providers";

const CompanySelection = () => {
  const { call: getAllUserCompanies } = useApi(UserService.getAllUserCompanies, 1000);
  const { call: loginWithCompany } = useApi(UserService.loginWithCompany, 1000);
  const company = useSelector(({ context }) => context.company);
  const companies = useSelector(({ context }) => context.companies);
  const [selectedCompany, setSelectedCompany] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
    } else if (isNonEmptyArray(companies)) {
      setSelectedCompany(companies[0]);
    }
  }, [company, companies]);
  useEffect(() => {
    getAllUserCompanies()
      .then((data) => {
        if (!Array.isArray(data.data) || data.data.length === 0) {
          KeycloakService.doLogout().then(() => {
            KeycloakService.clearToken();
          });
        } else {
          dispatch(setCompanies(data.data));
        }
      })
      .catch((err) => {
        dispatch(setDomain(""));
        KeycloakService.doLogout().then(() => {
          KeycloakService.clearToken();
        });
        console.error(err);
      });
  }, []);
  const handleChange = (companySelection) => {
    setSelectedCompany(companySelection);
  };
  useEffect(() => {
    if (isNonEmptyArray(companies) && companies.length === 1) {
      loginWithCompany({ companyId: companies[0].id })
        .then(() => {
          dispatch(setCompany(companies[0]));
          dispatch(setToken(KeycloakService.getToken()));
          history.push(NavUtils.gotoMainMenu());
        })
        .catch((err) => console.error(err));
    }
  }, [companies]);
  const handleLoginWithCompany = () => {
    if (!selectedCompany) {
      return;
    }
    loginWithCompany({ companyId: selectedCompany.id })
      .then(() => {
        dispatch(setCompany(selectedCompany));
        dispatch(setToken(KeycloakService.getToken()));
        history.push(NavUtils.gotoMainMenu());
      })
      .catch((err) => console.error(err));
  };
  const handleGoBack = () => {
    if (parseJwt(KeycloakService.getToken()).groups?.length === 1) {
      KeycloakService.doLogout();
    } else {
      history.push(NavUtils.gotoHome());
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.companySelectionContainer}>
        <div className={styles.helperText}>{translate("company-selection.select-bu")}</div>
        <div className={styles.companyList}>
          {isNonEmptyArray(companies) &&
            companies.map((company) => (
              <CompanyRow key={company.id} company={company} selectedCompany={selectedCompany} onClick={handleChange} />
            ))}
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <CustomButton variant="outlined" onClick={handleGoBack}>
          {translate("common:btn.back")}
        </CustomButton>
        <CustomButton disabled={!selectedCompany} variant="contained" onClick={handleLoginWithCompany}>
          {translate("common:btn.next")}
        </CustomButton>
      </div>
    </div>
  );
};

export default exportView({
  path: "/company-selection",
  component: CompanySelection,
  localesPath: "/company-selection/locales",
});
