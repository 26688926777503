import React from "react";
import { Route, Switch } from "react-router-dom";
import { InstanceSelection, Introduction, views } from "../../views";
import { UserBar } from "../../user-bar";
import { NavRouteWrapper } from "./components";
import { Redirect } from "react-router-dom";
import { KeycloakService } from "../../sevices";
import styles from "./AppRoutes.module.css";
import { ProjectSelectionSidePanel } from "../../common/components";
const generateComponent = (view, index) => {
  return (
    <Route key={index} exact path={view.path}>
      <NavRouteWrapper localesPath={view.localesPath}>
        <view.component />
      </NavRouteWrapper>
    </Route>
  );
};

const AppRoutes = () => {
  const isLogged = KeycloakService.isLoggedIn();
  if (isLogged) {
    return (
      <div className={styles.container}>
        <UserBar />
        <ProjectSelectionSidePanel />
        <Switch>
          {Object.keys(views).map((v, index) => generateComponent(views[v], index))}
          <Route exact path="/">
            <InstanceSelection />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
    );
  } else {
    return (
      <Switch>
        <Route path="*">
          <Introduction />
        </Route>
      </Switch>
    );
  }
};

export default AppRoutes;
