import React, { useMemo, useState } from "react";
import { SelectionInformation } from "./components";
import { CustomButton } from "../../../../../common/components";
import styles from "./Generate.module.css";
import { translate } from "../../../../../common/providers";
import { useDispatch, useSelector } from "react-redux";
import { isNonEmptyArray, mergeArrays } from "../../../../../common/utils";
import SummarySidePanel from "../../summary-side-panel/SummarySidePanel";
import { useApi } from "../../../../../common/hooks";
import { ContentService } from "../../../../../api";
import { extractChapterAndContentIds } from "./utils";
import SelectionPreview from "../../selection-preview/SelectionPreview";
import { SUMMARY_MODES } from "../../../constants/summary-modes";
import { setGeneratedContent, setSyntetizedContent } from "../../../slice/multi-project-search-slice";

const Generate = () => {
  const { call: getContentSummary } = useApi(ContentService.getContentSummary);
  const { call: getGenerateChapter } = useApi(ContentService.getGenerateChapter);
  const savedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedContent);
  const savedProjectContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedProjectContent);
  const syntetizedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.syntetizedContent);
  const generatedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.generatedContent);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [summaryMode, setSummaryMode] = useState(SUMMARY_MODES.GENERATE);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState("");
  const dispatch = useDispatch();
  const savedCurrentProjectContent = useMemo(
    () => savedProjectContent.find((spc) => spc.projectId === projectId)?.content || [],
    [savedProjectContent, projectId]
  );
  const handleCloseSummarySidePanel = () => {
    setLoadingSummary(false);
    setSummary("");
  };
  const handleSynthetize = () => {
    if (!isNonEmptyArray(savedContent) && !isNonEmptyArray(savedCurrentProjectContent)) {
      return;
    }
    setSummaryMode(SUMMARY_MODES.SYNTETIZE);
    if (syntetizedContent) {
      setSummary(syntetizedContent);
    } else {
      const deliverable = extractChapterAndContentIds(savedContent);
      const tender = extractChapterAndContentIds(savedCurrentProjectContent);
      setLoadingSummary(true);
      getContentSummary({
        informationIds: mergeArrays(deliverable.informationIds, tender.informationIds),
        contentIds: mergeArrays(deliverable.contentIds, tender.contentIds),
      })
        .then((data) => {
          setSummary(data.value);
          dispatch(setSyntetizedContent(data.value));
        })
        .catch(console.error)
        .finally(() => setLoadingSummary(false));
    }
  };
  const handleGenerateChapter = () => {
    if (!isNonEmptyArray(savedContent) || !isNonEmptyArray(savedCurrentProjectContent)) {
      return;
    }
    setSummaryMode(SUMMARY_MODES.GENERATE);
    if (generatedContent) {
      setSummary(generatedContent);
    } else {
      setLoadingSummary(true);
      const deliverable = extractChapterAndContentIds(savedContent);
      const tender = extractChapterAndContentIds(savedCurrentProjectContent);
      getGenerateChapter({
        deliverableInformationIds: deliverable.informationIds,
        deliverableContentIds: deliverable.contentIds,
        tenderInformationIds: tender.informationIds,
        tenderContentIds: tender.contentIds,
      })
        .then((data) => {
          setSummary(data.value);
          dispatch(setGeneratedContent(data.value));
        })
        .catch(console.error)
        .finally(() => setLoadingSummary(false));
    }
  };
  return (
    <div className={styles.container}>
      <SelectionInformation />
      <div className={styles.buttonContainer}>
        <CustomButton
          disabled={!isNonEmptyArray(savedContent) && !isNonEmptyArray(savedCurrentProjectContent)}
          variant="outlined"
          onClick={handleSynthetize}
        >
          {translate("multi-projects-search.generate.btn.synthetize-content")}
        </CustomButton>
        <CustomButton
          disabled={!isNonEmptyArray(savedContent) || !isNonEmptyArray(savedCurrentProjectContent)}
          variant="contained"
          onClick={handleGenerateChapter}
        >
          {translate("multi-projects-search.generate.btn.generate-chapter")}
        </CustomButton>
      </div>
      <div className={styles.savedContentContainer}>
        <SelectionPreview contextAllowed />
      </div>
      {(summary || loadingSummary) && (
        <SummarySidePanel
          content={summary}
          isLoading={loadingSummary}
          summaryMode={summaryMode}
          onClose={handleCloseSummarySidePanel}
        />
      )}
    </div>
  );
};

export default Generate;
