import React, { useMemo } from "react";
import { ProjectSelection, Search } from "../..";
import { IdentifiedContents } from "./components";
import { translate } from "../../../../../common/providers";
import { ViewTabs } from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH_TABS } from "../../../constants/tabs";
import { setSelectedSearchTab } from "../../../slice/multi-project-search-slice";
import styles from "../../../MultiProjectSearch.module.css";

const SearchContents = () => {
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const projectId = useSelector(({ context }) => context.project?.id);
  const dispatch = useDispatch();
  const tabHeaders = useMemo(
    () => [
      {
        id: SEARCH_TABS.DELIVERABLE,
        label: translate("multi-projects-search.tab-header.deliverable"),
        tab: "DeliverableTab",
      },
      {
        id: SEARCH_TABS.PROJECT,
        label: translate("multi-projects-search.tab-header.project"),
        tab: "ProjectTab",
      },
    ],
    []
  );

  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedSearchTab(tab));
  };

  return (
    <>
      <ViewTabs
        className={styles.tabs}
        selectedTab={selectedSearchTab}
        tabIdentifier="id"
        tabs={tabHeaders}
        onChange={handleChangeTab}
      />
      {(((selectedSearchTab === SEARCH_TABS.PROJECT && projectId) || selectedSearchTab === SEARCH_TABS.DELIVERABLE) && (
        <>
          <Search />
          <IdentifiedContents />
        </>
      )) || <ProjectSelection />}
    </>
  );
};

export default SearchContents;
