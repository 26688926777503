import { isNonEmptyArray } from "../../../../../../common/utils";

export const extractChapterAndContentIds = (contents) => {
  let informationIds = [];
  let contentIds = [];
  contents.forEach((sc) => {
    if (isNonEmptyArray(sc.recievedRectangles)) {
      sc.recievedRectangles.forEach((recievedRectangle) => {
        if (isNonEmptyArray(recievedRectangle.rectangles)) {
          recievedRectangle.rectangles.forEach((rectangle) => {
            contentIds = [...contentIds, rectangle.contentId];
          });
        }
      });
    } else {
      informationIds = [...informationIds, sc.informationId];
    }
  });
  return {
    informationIds: isNonEmptyArray(informationIds) ? informationIds : undefined,
    contentIds: isNonEmptyArray(contentIds) ? contentIds : undefined,
  };
};
