import React, { useMemo } from "react";
import { ProjectSelection } from "../..";
import { isNonEmptyArray } from "../../../../../common/utils";
import { CustomButton, ViewTabs } from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../../../common/providers";
import {
  refreshSavedContent,
  refreshSavedProjectContent,
  setSelectedSavedTab,
} from "../../../slice/multi-project-search-slice";
import { IdentifiedContents } from "./components";
import { SAVE_TABS } from "../../../constants/tabs";
import styles from "./SavedContents.module.css";
import stylesTab from "../../../MultiProjectSearch.module.css";

const SavedContents = () => {
  const savedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedContent);
  const savedProjectContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedProjectContent);
  const selectedSavedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSavedTab);
  const projectId = useSelector(({ context }) => context.project?.id);

  const dispatch = useDispatch();
  const tabHeaders = useMemo(
    () => [
      {
        id: SAVE_TABS.DELIVERABLE,
        label: translate("multi-projects-search.tab-header.deliverable"),
        tab: "DeliverableTab",
      },
      {
        id: SAVE_TABS.PROJECT,
        label: translate("multi-projects-search.tab-header.project"),
        tab: "ProjectTab",
      },
    ],
    []
  );
  const deleteBtnDisabled = useMemo(() => {
    if (selectedSavedTab === SAVE_TABS.DELIVERABLE) {
      return !isNonEmptyArray(savedContent);
    }
    if (selectedSavedTab === SAVE_TABS.PROJECT && projectId) {
      return !isNonEmptyArray(savedProjectContent.find((spc) => spc.projectId === projectId)?.content);
    }
    return true;
  }, [selectedSavedTab, savedContent, savedProjectContent, projectId]);

  const handleDeleteSavedContents = () => {
    if (selectedSavedTab === SAVE_TABS.DELIVERABLE) {
      dispatch(refreshSavedContent());
    }
    if (selectedSavedTab === SAVE_TABS.PROJECT && projectId) {
      dispatch(refreshSavedProjectContent(projectId));
    }
  };
  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedSavedTab(tab));
  };
  return (
    <>
      <ViewTabs
        className={stylesTab.tabs}
        selectedTab={selectedSavedTab}
        tabIdentifier="id"
        tabs={tabHeaders}
        onChange={handleChangeTab}
      />
      {(((selectedSavedTab === SAVE_TABS.PROJECT && projectId) || selectedSavedTab === SAVE_TABS.DELIVERABLE) && (
        <>
          <div className={styles.savedContents__actions}>
            <CustomButton disabled={deleteBtnDisabled} variant="outlined" onClick={handleDeleteSavedContents}>
              {translate("common:btn.delete-all")}
            </CustomButton>
          </div>
          <IdentifiedContents />
        </>
      )) || <ProjectSelection />}
    </>
  );
};

export default SavedContents;
