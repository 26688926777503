import React, { useMemo } from "react";
import { IconComponent, icon } from "../../../../../../../common/components";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import styles from "./SelectionInformation.module.css";

const SelectionInformation = () => {
  const savedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedContent);
  const savedProjectContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedProjectContent);
  const projectId = useSelector(({ context }) => context.project?.id);
  const savedCurrentProjectContent = useMemo(
    () => savedProjectContent.find((spc) => spc.projectId === projectId)?.content || [],
    [savedProjectContent, projectId]
  );
  const ideaText = useMemo(() => {
    if (savedContent.length === 1) {
      return translate("multi-projects-search.generate.selected-idea");
    }
    if (savedContent.length > 1) {
      return translate("multi-projects-search.generate.selected-ideas", { count: savedContent.length });
    }
    return translate("multi-projects-search.generate.no-selected-ideas");
  }, [savedContent]);
  const elementsText = useMemo(() => {
    if (savedCurrentProjectContent.length === 1) {
      return translate("multi-projects-search.generate.selected-element");
    }
    if (savedCurrentProjectContent.length > 1) {
      return translate("multi-projects-search.generate.selected-elements", {
        count: savedCurrentProjectContent.length,
      });
    }
    return translate("multi-projects-search.generate.no-selected-elements");
  }, [savedCurrentProjectContent]);
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <IconComponent
            color={savedContent.length > 0 ? "var(--color-green)" : "var(--color-red)"}
            icon={savedContent.length > 0 ? icon.faCircleCheck : icon.faXmark}
            size="md"
          />
        </div>
        <span>{ideaText}</span>
      </div>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <IconComponent
            color={savedCurrentProjectContent.length > 0 ? "var(--color-green)" : "var(--color-yellow)"}
            icon={savedCurrentProjectContent.length > 0 ? icon.faCircleCheck : icon.faExclamationTriangle}
            size="md"
          />
        </div>
        <span>{elementsText}</span>
      </div>
    </div>
  );
};

export default SelectionInformation;
