import React, { useMemo, useState } from "react";
import {
  CircularLoader,
  CustomButton,
  SidePanel,
  SidePanelContent,
  ViewTabs,
  icon,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./SummarySidePanel.module.css";
import { WordUtils } from "../../../../common/utils";
import { SUMMARY_TABS, TABS } from "../../constants/tabs";
import SelectionPreview from "../selection-preview/SelectionPreview";
import { SUMMARY_MODES } from "../../constants/summary-modes";
import { useDispatch } from "react-redux";
import {
  setInitialEditContent,
  setSelectedTab as setSelectedTabDispatch,
} from "../../slice/multi-project-search-slice";

const SummarySidePanel = ({ content, isLoading, onClose, summaryMode }) => {
  const [selectedTab, setSelectedTab] = useState(SUMMARY_TABS.VISUALIZATION);
  const dispatch = useDispatch();
  const tabHeaders = useMemo(
    () => [
      {
        iconName: icon.faPen,
        id: SUMMARY_TABS.VISUALIZATION,
        label: translate("multi-projects-search.summary-side-panel.tabs.visualization"),
        tab: "VisualizationTab",
      },
      {
        iconName: icon.faFile,
        id: SUMMARY_TABS.SOURCES,
        label: translate("multi-projects-search.summary-side-panel.tabs.sources"),
        tab: "SourcesTab",
      },
    ],
    []
  );
  const handleInsert = () => {
    WordUtils.insertTextToWord(content);
  };
  const handleChangeTab = (_, tab) => {
    setSelectedTab(tab);
  };
  const handleInsertToEdit = () => {
    dispatch(setInitialEditContent(content));
    onClose();
    dispatch(setSelectedTabDispatch(TABS.EDITION));
  };
  return (
    <SidePanel open={!!content || isLoading} size={65} onClose={onClose}>
      <SidePanelContent title={translate("multi-projects-search.summary-side-panel.title")} onClose={onClose}>
        <div className={styles.container}>
          <ViewTabs
            className={styles.tabs}
            selectedTab={selectedTab}
            tabIdentifier="id"
            tabs={tabHeaders}
            onChange={handleChangeTab}
          />
          {(selectedTab === SUMMARY_TABS.VISUALIZATION && (
            <>
              {!isLoading && (
                <div className={styles.btnContainer}>
                  <CustomButton
                    color="secondary"
                    startIcon={icon.faPenToSquare}
                    variant="outlined"
                    onClick={handleInsertToEdit}
                  >
                    {translate("multi-projects-search.summary-side-panel.open-in-editor")}
                  </CustomButton>
                  <CustomButton startIcon={icon.faArrowLeft} variant="contained" onClick={handleInsert}>
                    {translate("common:btn.insert")}
                  </CustomButton>
                </div>
              )}
              {(isLoading && (
                <div className={styles.loaderContainer}>
                  <CircularLoader />
                </div>
              )) || <div className={styles.contentContainer}>{content}</div>}
            </>
          )) || (
            <div className={styles.selectionPreview__container}>
              <SelectionPreview disableRemove contextAllowed={summaryMode === SUMMARY_MODES.GENERATE} />
            </div>
          )}
        </div>
      </SidePanelContent>
    </SidePanel>
  );
};

export default SummarySidePanel;
