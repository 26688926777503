import client from "../client/client";
import { encodeParam } from "./utils/utils";

class PdfViewService {
  autoComplete({ projectId }, input, cancelToken) {
    return client.get(`/searchcard/projects/${projectId}/auto-complete`, {
      params: { input: encodeParam(input) },
      config: { cancelToken },
    });
  }
}

export default new PdfViewService();
