import { createSlice } from "@reduxjs/toolkit";
import { getPage } from "./multi-project-search-thunk";
import { SAVE_TABS, SEARCH_TABS, TABS } from "../constants/tabs";
import { OPERATORS } from "../../../common/constants";
import { removeNullFilters } from "./multi-project-search-utils";

const initialState = {
  content: [],
  savedContent: [],
  savedProjectContent: [],
  selectedTab: TABS.SEARCH,
  selectedSearchTab: SEARCH_TABS.DELIVERABLE,
  selectedSavedTab: SAVE_TABS.DELIVERABLE,
  editContent: [],
  currentEditContentIndex: 0,
  generatedContent: "",
  syntetizedContent: "",
  filters: { input: "", granularity: [] },
  projectFilters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
};
const multiProjectSearchSlice = createSlice({
  name: "multiProjectSearch",
  initialState,
  reducers: {
    resetContent: (state) => ({
      ...state,
      content: [],
    }),
    addNewSavedContent: (state, { payload }) => ({
      ...state,
      syntetizedContent: "",
      generatedContent: "",
      savedContent: [
        ...state.savedContent,
        {
          id: state.savedContent?.length > 0 ? state.savedContent[state.savedContent.length - 1].id + 1 : 1,
          ...payload,
        },
      ],
    }),
    addNewSavedProjectContent: (state, { payload }) => {
      const { projectId, content } = payload;
      if (state.savedProjectContent.findIndex((spc) => spc.projectId === projectId) >= 0) {
        return {
          ...state,
          generatedContent: "",
          syntetizedContent: "",
          savedProjectContent: state.savedProjectContent.map((spc) => {
            if (spc.projectId === projectId) {
              return {
                ...spc,
                content: [
                  ...spc.content,
                  {
                    id: spc.content?.length > 0 ? spc.content[spc.content.length - 1].id + 1 : 1,
                    ...content,
                  },
                ],
              };
            }
            return spc;
          }),
        };
      } else {
        return {
          ...state,
          generatedContent: "",
          syntetizedContent: "",
          savedProjectContent: [
            ...state.savedProjectContent,
            {
              projectId,
              content: [
                {
                  id: 1,
                  ...content,
                },
              ],
            },
          ],
        };
      }
    },
    refreshSavedContent: (state) => ({ ...state, syntetizedContent: "", generatedContent: "", savedContent: [] }),
    refreshSavedProjectContent: (state, { payload }) => ({
      ...state,
      generatedContent: "",
      syntetizedContent: "",
      savedProjectContent: state.savedProjectContent.filter((sc) => sc.projectId !== payload),
    }),
    removeSavedContent: (state, { payload }) => ({
      ...state,
      syntetizedContent: "",
      generatedContent: "",
      savedContent: state.savedContent.filter((sc) => sc.id !== payload),
    }),
    removeSavedProjectContent: (state, { payload }) => {
      const { contentId, projectId } = payload;
      return {
        ...state,
        generatedContent: "",
        syntetizedContent: "",
        savedProjectContent: state.savedProjectContent.map((spc) => {
          if (spc.projectId === projectId) {
            return {
              ...spc,
              content: spc.content.filter((sc) => sc.id !== contentId),
            };
          }
          return spc;
        }),
      };
    },
    setSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
    setInitialEditContent: (state, { payload }) => ({
      ...state,
      editContent: [payload],
      currentEditContentIndex: 0,
    }),
    addEditContent: (state, { payload }) => ({
      ...state,
      editContent: [...state.editContent, payload],
      currentEditContentIndex: state.editContent.length,
    }),
    incrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex + 1,
    }),
    decrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex - 1,
    }),
    setSelectedSearchTab: (state, { payload }) => ({
      ...state,
      selectedSearchTab: payload,
    }),
    setSelectedSavedTab: (state, { payload }) => ({
      ...state,
      selectedSavedTab: payload,
    }),
    setGeneratedContent: (state, { payload }) => ({
      ...state,
      generatedContent: payload,
    }),
    setSyntetizedContent: (state, { payload }) => ({
      ...state,
      syntetizedContent: payload,
    }),
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    setProjectFilters: (state, { payload: filters }) => {
      const newFilters = removeNullFilters(filters);
      return {
        ...state,
        projectFilters: newFilters,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPage.pending, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: [...state.content, { page, isLoading: true }],
      };
    });
    builder.addCase(getPage.fulfilled, (state, action) => {
      const { page } = action.meta.arg;
      const { payload } = action;
      const blobImage = URL.createObjectURL(payload);
      return {
        ...state,
        content: state.content.map((c) =>
          c.page === page ? { ...c, page, image: blobImage, isLoading: false } : { ...c, truePage: c.page }
        ),
      };
    });
    builder.addCase(getPage.rejected, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: state.content.map((c) => (c.page === page ? { ...c, page, image: null, isLoading: false } : c)),
      };
    });
  },
});
export { getPage };
export const {
  resetContent,
  setSelectedTab,
  setSelectedSearchTab,
  setSelectedSavedTab,
  setFilters,
  setProjectFilters,
  setGeneratedContent,
  setSyntetizedContent,
  setInitialEditContent,
  addEditContent,
  incrementEditContentIndex,
  decrementEditContentIndex,
  addNewSavedContent,
  addNewSavedProjectContent,
  refreshSavedContent,
  refreshSavedProjectContent,
  removeSavedContent,
  removeSavedProjectContent,
} = multiProjectSearchSlice.actions;
export default multiProjectSearchSlice.reducer;
