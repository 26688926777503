import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ClickAwayListener, OutlinedInput } from "@mui/material";
import { CircularLoader, CustomDialog, icon, PdfViewer, SidePanel, SidePanelContent } from "../..";
import { ContentService, DocumentService } from "../../../../api";
import styles from "../Preview.module.css";
import { translate } from "../../../providers";

import { CustomIconButton } from "../../buttons";
import { useApi, useCurrentHeight } from "../../../hooks";
import { WordUtils, downloadFile, isNonEmptyArray } from "../../../utils";

const ZOOM_STEP = 0.1;
const ZOOM_MIN = 0.5;
const ZOOM_MAX = 2;

const getRoundedHeight = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedWidth = (dimension, start, end) => Math.round((end - start) * dimension) + 12;
const getRoundedAxisCoordinate = (axis, coordinate) => Math.round(axis * coordinate) - 6;
const handleCalculatePageStart = (page, numberOfPages) => {
  if (numberOfPages === 1) {
    return page;
  }
  if (numberOfPages % 2 === 0) {
    return page + numberOfPages / 2 - 1;
  }
  return page + Math.floor(numberOfPages / 2);
};
const handleCalculatAdjPages = (numberOfPages) => {
  return Math.ceil(numberOfPages / 2);
};
export default function ChapterPreview({
  blinking = false,
  coordinates,
  docId,
  extraPages = 0,
  infoId = null,
  numOfPages = 3,
  onActive: onActiveFromParent = null,
  onClose,
  open,
  page,
  recievedRectangles,
}) {
  const { call: onGetChapterString } = useApi(ContentService.getChapterString);
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const { call: onGetDocumentPreviewDimensions } = useApi(DocumentService.getDocumentPreviewDimensions);
  const { call: onGetSelectedContentFromContentIds } = useApi(ContentService.getSelectedContentFromContentIds);
  const { call: onGetPage } = useApi(DocumentService.getPage);
  const refPrimary = useRef();
  const refSecondary = useRef();
  const [pageDimensions, setPageDimensions] = useState([]);
  const [previewContent, setPreviewContent] = useState(null);
  const [manual, setManual] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [manualZoom, setManualZoom] = useState(1);
  const [textToInsert, setTextToInsert] = useState("");
  const [numberOfPages, setNumberOfPages] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const cautionBannerDisabled = useSelector(({ context }) => context.cautionBannerDisabled);
  const currentHeight = useCurrentHeight({ difference: cautionBannerDisabled ? -73 : -113 });

  useEffect(() => {
    setIsLoading(previewContent?.find((c) => c.truePage === page)?.isLoading);
  }, [previewContent]);
  useEffect(() => {
    if (docId && page && open && previewContent === null) {
      onGetDocumentPreviewDimensions(
        { docId, page: handleCalculatePageStart(page, numOfPages) },
        { adjPages: handleCalculatAdjPages(numOfPages) + extraPages }
      )
        .then((dataPage) => {
          setPageDimensions(dataPage.map((p) => ({ height: p.height, width: p.width })));
          setPreviewContent(dataPage.map((q) => ({ truePage: q.pageNumber, isLoading: true })));
        })
        .catch((err) => console.error(err));
    }
    if (isNonEmptyArray(previewContent) && loadingPreview) {
      previewContent.forEach((content, index) => {
        if (!content?.image && content?.isLoading) {
          onGetPage({ docId }, { page: content.truePage })
            .then((dataImage) => {
              if (dataImage) {
                const blobImage = URL.createObjectURL(dataImage);
                setPreviewContent((prev) =>
                  prev?.map(
                    (prevContent) =>
                      (prevContent.truePage === content.truePage && {
                        ...content,
                        page: index + 1,
                        image: blobImage,
                        isLoading: false,
                      }) ||
                      prevContent
                  )
                );
              }
            })
            .catch((err) => {
              console.error(err);
              setLoadingPreview(false);
            })
            .finally(() => setLoadingPreview(false));
        }
      });
    }
    if ((page === 0 || previewContent?.length === 0) && loadingPreview) {
      setLoadingPreview(false);
    }
  }, [numOfPages, extraPages, docId, loadingPreview, page, previewContent, open]);

  useEffect(() => {
    if (open) {
      setZoom(1);
    } else {
      setLoadingPreview(true);
      setPreviewContent(null);
    }
  }, [open]);

  useEffect(() => {
    if (isNonEmptyArray(pageDimensions)) {
      const maxWidth = pageDimensions.reduce((prev, current) => (prev.width > current.width ? prev : current)).width;
      const { innerWidth } = window;
      const possibilities = Array.from(
        Array(Math.trunc((ZOOM_MAX - ZOOM_MIN) / ZOOM_STEP)),
        (_, i) => Math.floor((ZOOM_MIN + i * ZOOM_STEP) * 100) / 100
      );
      const goal = (innerWidth * 0.45 - 70) / maxWidth;
      const finalZoom = possibilities.reduce((prev, curr) =>
        Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
      );
      setZoom(finalZoom);
      setManualZoom(finalZoom * 100);
    }
  }, [pageDimensions]);

  const handleZoomOut = () => {
    if (zoom > ZOOM_MIN) {
      setZoom((prev) => prev - ZOOM_STEP);
    }
  };
  const handleZoomIn = () => {
    if (zoom < ZOOM_MAX) {
      setZoom((prev) => prev + ZOOM_STEP);
    }
  };
  const handleChangeZoom = (e) => {
    const { value } = e.target;
    setManualZoom(value);
    if (value / 100 >= ZOOM_MIN && zoom / 100 <= ZOOM_MAX) {
      setZoom(Math.floor(value) / 100);
    }
  };
  const handleOpenManual = () => {
    setManual((prev) => !prev);
  };

  useEffect(() => {
    setTimeout(() => {
      if (open && refPrimary?.current) {
        refPrimary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else if (open && refSecondary?.current) {
        refSecondary.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, [open, isLoading, refPrimary, refSecondary, zoom]);

  const getRender = (props, rec, index) => (
    <>
      <div
        key={infoId * index}
        ref={rec.primary ? refPrimary : refSecondary}
        className={styles.overlay}
        data-blinking={blinking}
        data-color={rec.primary ? "primary" : "secondary"}
        style={{
          left: getRoundedAxisCoordinate(props.width, rec.x1),
          top: getRoundedAxisCoordinate(props.height, rec.y1),
          width: getRoundedWidth(props.width, rec.x1, rec.x2),
          height: getRoundedHeight(props.height, rec.y1, rec.y2),
        }}
      />
    </>
  );
  const getAdjustedCoordinates = () => {
    if (numOfPages === 1) {
      return [{ ...coordinates[0], x1: 0.07, x2: 0.94, y2: coordinates[1].y2 }];
    }
    if (numOfPages === 2) {
      return coordinates.map((coord, index) => ({
        x1: 0.07,
        x2: 0.94,
        y1: index === 0 ? coord.y1 : 0.02,
        y2: index === 0 ? 0.98 : coord.y2,
        pageNumber: page + index,
      }));
    }
    return Array.apply(null, Array(numOfPages)).map((_, index) => ({
      x1: 0.07,
      x2: 0.94,
      y1: index === 0 ? coordinates[0].y1 : 0.02,
      y2: index === numOfPages - 1 ? coordinates[1].y2 : 0.98,
      contentId: page + index,
      pageNumber: page + index,
    }));
  };
  const handleRenderLayers = (props) => {
    if ((isNonEmptyArray(coordinates) || isNonEmptyArray(recievedRectangles)) && zoom > 0) {
      let adjustedCoordinates = [];
      if (recievedRectangles) {
        recievedRectangles.forEach((recievedRectangle) => {
          if (isNonEmptyArray(recievedRectangle?.rectangles)) {
            recievedRectangle.rectangles.forEach((rec) => {
              adjustedCoordinates = [
                ...adjustedCoordinates,
                { x1: rec.x1, x2: rec.x2, y1: rec.y1, y2: rec.y2, pageNumber: recievedRectangle.pageNumber },
              ];
            });
          }
        });
      } else {
        adjustedCoordinates = coordinates.length === 1 ? coordinates : getAdjustedCoordinates();
      }
      if (adjustedCoordinates.some((coord) => coord?.pageNumber)) {
        return adjustedCoordinates.map(
          (rec, index) => rec.pageNumber === props.metadata.truePage && getRender(props, rec, index)
        );
      }
      if (props.metadata.truePage === page) {
        return adjustedCoordinates.map((rec, index) => getRender(props, rec, index));
      }
    }
    return null;
  };
  const handleDownload = () => {
    onDownloadDocument({ docId })
      .then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
      .catch((err) => console.error(err));
  };
  const requestContentString = () => {
    if (infoId && docId) {
      onGetChapterString({ informationId: infoId, docId: docId })
        .then((data) => {
          if (data.numberOfPages < 3) {
            WordUtils.insertTextToWord(data.allContent.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.allContent.replaceAll("\\", "\n"));
            setNumberOfPages(data.numberOfPages);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleInsertSelection = () => {
    if (!isNonEmptyArray(recievedRectangles)) {
      return;
    }
    let contentIds = [];
    recievedRectangles.forEach((recievedRectangle) => {
      if (isNonEmptyArray(recievedRectangle?.rectangles)) {
        recievedRectangle.rectangles.forEach((rec) => {
          if (!contentIds.some((cId) => rec.contentId === cId)) contentIds = [...contentIds, rec.contentId];
        });
      }
    });
    if (isNonEmptyArray(contentIds)) {
      onGetSelectedContentFromContentIds({ docId }, { contentIds })
        .then((data) => {
          if (recievedRectangles.length < 3) {
            WordUtils.insertTextToWord(data.value.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.value.replaceAll("\\", "\n"));
            setNumberOfPages(recievedRectangles.length);
          }
        })
        .catch(console.error);
    }
  };
  const handleInsert = () => {
    if (recievedRectangles) {
      handleInsertSelection();
    } else {
      requestContentString();
    }
  };
  const handleInsertText = () => {
    WordUtils.insertTextToWord(textToInsert);
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const handleCloseInsertDialog = () => {
    setTextToInsert("");
    setNumberOfPages(0);
  };
  return (
    <SidePanel open={open} size={65} onActive={onActiveFromParent} onClose={onClose}>
      <SidePanelContent
        secondaryActions={
          <span className={styles.secondary__actions}>
            <CustomIconButton
              color="secondary"
              icon={icon.faDownload}
              size="sm"
              tooltip={translate("common:btn.download")}
              variant="outlined"
              onClick={handleDownload}
            />
            <CustomIconButton
              color="secondary"
              icon={icon.faArrowLeft}
              size="sm"
              tooltip={translate("common:btn.insert-chapter")}
              variant="outlined"
              onClick={handleInsert}
            />
          </span>
        }
        title={translate("common:component.preview.title")}
        onClose={onClose}
      >
        <div className={styles.container}>
          <>
            {!loadingPreview &&
              pageDimensions?.length === previewContent?.length &&
              isNonEmptyArray(previewContent) && (
                <>
                  <PdfViewer
                    content={previewContent}
                    height={currentHeight}
                    numberOfPages={pageDimensions.length}
                    pageDimensions={pageDimensions}
                    zoom={zoom * 150}
                    onRenderLayers={handleRenderLayers}
                  />
                </>
              )}
            {loadingPreview && (
              <div>
                <CircularLoader legacy />
              </div>
            )}
            {!loadingPreview && !isNonEmptyArray(previewContent) && (
              <span>{translate("common:component.preview.unavailable-image")}</span>
            )}
          </>
          {isNonEmptyArray(previewContent) && (
            <div className={styles.zoomButtons}>
              {(!manual && (
                <>
                  <CustomIconButton icon={icon.faMinus} onClick={handleZoomOut} />
                  <span role="presentation" onClick={handleOpenManual}>{`${Math.trunc(zoom * 100)}%`}</span>
                  <CustomIconButton icon={icon.faPlus} onClick={handleZoomIn} />
                </>
              )) || (
                <ClickAwayListener onClickAway={handleOpenManual}>
                  <OutlinedInput
                    classes={{
                      root: styles.input,
                    }}
                    type="number"
                    value={manualZoom}
                    onChange={handleChangeZoom}
                  />
                </ClickAwayListener>
              )}
            </div>
          )}
          <CustomDialog
            iconColor="var(--color-red)"
            iconModel={icon.faExclamationTriangle}
            open={textToInsert && numberOfPages}
            submitLabel={translate("common:btn.validate")}
            subTitle={translate("multi-project-search.identified-content-box.sub-title", { numberOfPages })}
            title={translate("common:btn.insert-chapter")}
            onClose={handleCloseInsertDialog}
            onSubmit={handleInsertText}
          />
        </div>
      </SidePanelContent>
    </SidePanel>
  );
}
