import React, { useEffect, useRef, useState } from "react";
import styles from "./IdentifiedContentBox.module.css";
import {
  CustomButton,
  CustomDialog,
  CustomIconButton,
  CustomTooltip,
  VerticalDivider,
  icon,
} from "../../../../common/components";
import { translate, translateDate } from "../../../../common/providers";
import { ContentService, DocumentService } from "../../../../api";
import { WordUtils, downloadFile } from "../../../../common/utils";
import { useApi } from "../../../../common/hooks";
import { useDispatch, useSelector } from "react-redux";
import { TABS } from "../../constants/tabs";
import { removeSavedContent, removeSavedProjectContent } from "../../slice/multi-project-search-slice";

const IdentifiedContentBox = ({
  disableRemove = false,
  identifiedContent,
  onDisplay,
  onDisplaySelection,
  onSaveContent,
  projectContentDeletion = false,
}) => {
  const { call: onGetChapterString } = useApi(ContentService.getChapterString);
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const { call: onGetPreviewInformationChapter } = useApi(ContentService.getPreviewInformationChapter);
  const selectedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedTab);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [textToInsert, setTextToInsert] = useState("");
  const [numberOfPages, setNumberOfPages] = useState("");
  const [hide, setHide] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();
  const textRef = useRef(null);

  const handleContentOverflow = () => {
    setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
  };
  useEffect(() => {
    handleContentOverflow();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleContentOverflow);
    return () => {
      window.removeEventListener("resize", handleContentOverflow);
    };
  }, []);
  const handleShowMore = () => {
    setHide((prev) => !prev);
  };
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const handleInsert = () => {
    WordUtils.insertTextToWord(textToInsert);
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const handleCloseInsertDialog = () => {
    setTextToInsert("");
    setNumberOfPages(0);
  };
  const requestContentString = () => {
    if (identifiedContent) {
      onGetChapterString({ informationId: identifiedContent.informationId, docId: identifiedContent.documentId })
        .then((data) => {
          if (data.numberOfPages < 3) {
            WordUtils.insertTextToWord(data.allContent.replaceAll("\\", "\n"));
          } else {
            setTextToInsert(data.allContent.replaceAll("\\", "\n"));
            setNumberOfPages(data.numberOfPages);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleDownload = () => {
    onDownloadDocument({ docId: identifiedContent.documentId })
      .then(({ data, filename }) => downloadFile({ data, filename, filetype: "zip" }))
      .catch((err) => console.error(err));
  };
  const handleSaveContent = () => {
    const { projectId, documentId, informationId } = identifiedContent;
    onGetPreviewInformationChapter({ docId: documentId, informationId })
      .then((data) => onSaveContent({ projectId, documentId, informationId, previewResponse: data, identifiedContent }))
      .catch((err) => console.error(err));
  };
  const handleClickDisplay = () => {
    const { projectId, documentId, informationId } = identifiedContent;
    onGetPreviewInformationChapter({ docId: documentId, informationId })
      .then((data) => onDisplay({ projectId, documentId, informationId, previewResponse: data, identifiedContent }))
      .catch((err) => console.error(err));
  };
  const handleDeleteSavedContent = () => {
    if (!projectContentDeletion) {
      dispatch(removeSavedContent(identifiedContent.id));
    }
    if (projectContentDeletion && projectId) {
      dispatch(removeSavedProjectContent({ projectId, contentId: identifiedContent.id }));
    }
  };
  const handleDisplayChapter = () => {
    onDisplaySelection(identifiedContent);
  };
  return (
    <div className={styles.container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {isHovering && (
        <div className={styles.hoverContainer}>
          {(selectedTab === TABS.SEARCH && (
            <>
              <CustomIconButton
                color="primary"
                icon={icon.faBookmark}
                size="sm"
                tooltip={translate("common:btn.save")}
                onClick={handleSaveContent}
              />
              <VerticalDivider inFlexContainer />
              <CustomIconButton
                color="primary"
                icon={icon.faDownload}
                size="sm"
                tooltip={translate("common:btn.download")}
                onClick={handleDownload}
              />
              <VerticalDivider inFlexContainer />
              <CustomIconButton
                color="primary"
                icon={icon.faFile}
                size="sm"
                tooltip={translate("common:btn.display")}
                onClick={handleClickDisplay}
              />
              <VerticalDivider inFlexContainer />
              <CustomIconButton
                color="primary"
                icon={icon.faArrowLeft}
                size="sm"
                tooltip={translate("common:btn.insert")}
                onClick={requestContentString}
              />
            </>
          )) || (
            <>
              <CustomIconButton
                color="primary"
                icon={icon.faFile}
                size="sm"
                tooltip={translate("common:btn.display")}
                onClick={handleDisplayChapter}
              />
              <VerticalDivider inFlexContainer />
              {!disableRemove && (
                <CustomIconButton
                  color="primary"
                  icon={icon.faCircleMinus}
                  size="sm"
                  tooltip={translate("common:btn.delete")}
                  onClick={handleDeleteSavedContent}
                />
              )}
            </>
          )}
        </div>
      )}
      <div>
        <div ref={textRef} className={styles.content} data-hide={hide}>
          {identifiedContent.content}
        </div>
        {isContentOverflowing && (
          <CustomButton className={styles.informationContent__btn} color="primary" onClick={handleShowMore}>
            {(hide && translate("common:btn.show-more")) || translate("common:btn.show-less")}
          </CustomButton>
        )}
      </div>
      <div className={styles.additionalInformation}>
        <div className={styles.additionalInformation__nameDate}>
          <CustomTooltip showWhenOverflow title={identifiedContent.projectName}>
            <div className={styles.projectName}>{identifiedContent.projectName}</div>
          </CustomTooltip>
          <VerticalDivider inFlexContainer />
          {translateDate(identifiedContent.projectCreationDate)}
        </div>
        <CustomTooltip showWhenOverflow title={identifiedContent.documentName}>
          <div className={styles.documentName}>{identifiedContent.documentName}</div>
        </CustomTooltip>
      </div>
      <CustomDialog
        iconColor="var(--color-red)"
        iconModel={icon.faExclamationTriangle}
        open={textToInsert && numberOfPages}
        submitLabel={translate("common:btn.validate")}
        subTitle={translate("multi-project-search.identified-content-box.sub-title", { numberOfPages })}
        title={translate("common:btn.insert-chapter")}
        onClose={handleCloseInsertDialog}
        onSubmit={handleInsert}
      />
    </div>
  );
};

export default IdentifiedContentBox;
