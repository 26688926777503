import React from "react";
import { MenuList } from "@mui/material";
import styles from "./SubMenus.module.css";
import { DropDown, MenuElement } from "..";
import { isNonEmptyArray } from "../../utils";
const SubMenus = React.forwardRef(({ onClose, open, subMenus }, ref) => {
  return (
    <DropDown ref={ref} open={open} position="bottom-end" onClose={onClose}>
      <MenuList className={styles.menuContainer}>
        {isNonEmptyArray(subMenus) && subMenus.map((menu) => <MenuElement key={menu.title} menu={menu} />)}
      </MenuList>
    </DropDown>
  );
});

export default SubMenus;
