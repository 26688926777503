import React, { useMemo } from "react";
import { exportView } from "../../navigation";
import { SearchContents, SavedContents, Generate, Edition } from "./components";
import styles from "./MultiProjectSearch.module.css";
import { ViewTabs, icon } from "../../common/components";
import { translate } from "../../common/providers";
import { TABS } from "./constants/tabs";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "./slice/multi-project-search-slice";

const MultiProjectSearch = () => {
  const selectedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedTab);
  const dispatch = useDispatch();

  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedTab(tab));
  };
  const tabHeaders = useMemo(
    () => [
      {
        iconName: icon.faMagnifyingGlass,
        id: TABS.SEARCH,
        label: translate("multi-projects-search.tab-header.search"),
        tab: "PositionsTab",
      },
      {
        iconName: icon.faBookmark,
        id: TABS.SAVED_CONTENTS,
        label: translate("multi-projects-search.tab-header.saved-contents"),
        tab: "CommentsTab",
      },
      {
        iconName: icon.faMagicWandSparkles,
        id: TABS.GENERATE,
        label: translate("multi-projects-search.tab-header.generate"),
        tab: "GenerateTab",
      },
      {
        iconName: icon.faPenToSquare,
        id: TABS.EDITION,
        label: translate("multi-projects-search.tab-header.edition"),
        tab: "EditionTab",
      },
    ],
    []
  );
  const handleTabViewLoader = () => {
    if (selectedTab === TABS.SEARCH) {
      return <SearchContents />;
    }
    if (selectedTab === TABS.SAVED_CONTENTS) {
      return <SavedContents />;
    }
    if (selectedTab === TABS.GENERATE) {
      return <Generate />;
    }
    if (selectedTab === TABS.EDITION) {
      return <Edition />;
    }
    return <SearchContents />;
  };

  return (
    <div className={styles.container}>
      <ViewTabs
        className={styles.tabs}
        selectedTab={selectedTab}
        tabIdentifier="id"
        tabs={tabHeaders}
        onChange={handleChangeTab}
      />
      {handleTabViewLoader()}
    </div>
  );
};

export { default as multiProjectSearchSlice } from "./slice/multi-project-search-slice";

export default exportView({
  path: "/multi-project-search",
  localesPath: "/multi-project-search/locales",
  component: MultiProjectSearch,
});
