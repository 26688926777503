import React from "react";
import { CustomIconButton } from "../../..";
import { icon } from "../../../icon-component";
import styles from "./SidePanelContent.module.css";

export default function SidePanelContent({
  autoTranslate = "yes",
  children,
  className = "",
  headerClassName = "",
  onClose,
  secondaryActions = null,
  title,
}) {
  return (
    <div className={styles.panel}>
      <div className={styles.panel__header}>
        <CustomIconButton
          className={styles.header__icon}
          icon={icon.faArrowLeft}
          iconClassname={styles["header__icon--large"]}
          onClick={onClose}
        />
        <span className={`${styles.header__text} ${headerClassName}`} translate={autoTranslate}>
          {title}
        </span>
        {secondaryActions && <span className={styles.header__secondaryActions}>{secondaryActions}</span>}
      </div>
      <div className={`${styles.panel__content} ${className}`}>{children}</div>
    </div>
  );
}
