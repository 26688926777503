import React, { useMemo, useState } from "react";
import styles from "./IdentifiedContents.module.css";
import { ChapterPreview } from "../../../../../../../common/components";
import { isNonEmptyArray } from "../../../../../../../common/utils";
import { useSelector } from "react-redux";
import IdentifiedContentBox from "../../../../identified-content-box/IdentifiedContentBox";
import { translate } from "../../../../../../../common/providers";
import { SAVE_TABS } from "../../../../../constants/tabs";

const IdentifiedContents = () => {
  const savedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedContent);
  const savedProjectContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedProjectContent);
  const projectId = useSelector(({ context }) => context.project?.id);
  const selectedSavedTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSavedTab);
  const [chapterPreview, setChapterPreview] = useState(null);
  const savedContentsToDisplay = useMemo(() => {
    if (selectedSavedTab === SAVE_TABS.PROJECT && projectId) {
      return savedProjectContent?.find((spc) => spc.projectId === projectId)?.content || [];
    }
    return savedContent;
  }, [selectedSavedTab, projectId, savedContent, savedProjectContent]);
  const handleDisplaySelection = (data) => {
    setChapterPreview(data);
  };
  const handleCloseChapterDisplay = () => setChapterPreview(null);
  return (
    <div className={styles.container}>
      {(isNonEmptyArray(savedContentsToDisplay) &&
        savedContentsToDisplay.map((sc) => (
          <IdentifiedContentBox
            key={sc.id}
            identifiedContent={sc}
            projectContentDeletion={selectedSavedTab === SAVE_TABS.PROJECT}
            onDisplaySelection={handleDisplaySelection}
          />
        ))) || (
        <div className={styles.emptyState}>
          {translate("multi-projects-search.identified-contents.saved.empty-state")}
        </div>
      )}
      <ChapterPreview
        coordinates={chapterPreview?.coordinates}
        docId={chapterPreview?.documentId}
        infoId={chapterPreview?.informationId}
        numOfPages={
          isNonEmptyArray(chapterPreview?.recievedRectangles)
            ? chapterPreview.recievedRectangles.length
            : chapterPreview?.numOfPages
        }
        open={!!chapterPreview}
        page={
          isNonEmptyArray(chapterPreview?.recievedRectangles)
            ? chapterPreview.recievedRectangles[0].pageNumber
            : chapterPreview?.page
        }
        projectId={chapterPreview?.projectId}
        recievedRectangles={chapterPreview?.recievedRectangles}
        onClose={handleCloseChapterDisplay}
      />
    </div>
  );
};

export default IdentifiedContents;
