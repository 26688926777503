import Root from "./Root";
import { AppContainer } from "react-hot-loader";
import { Provider } from "react-redux";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import "./index.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import store from "./store";
import { HashRouter } from "react-router-dom";
import { KeycloakService, TokenRefreshComponent } from "./sevices";
//  /* global document, Office, module, require */
/* global Office,  */

initializeIcons();

let isOfficeInitialized = false;

const title = "Aitenders plugin";

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <TokenRefreshComponent />
      <AppContainer>
        <HashRouter>
          <Component isOfficeInitialized={isOfficeInitialized} title={title} />
        </HashRouter>
      </AppContainer>
    </Provider>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  KeycloakService.initKeycloak(
    setTimeout(() => {
      render(Root);
    }, 500)
  );
});

/* Initial render showing a progress bar */
render(Root);
if (module.hot) {
  module.hot.accept("./Root", () => {
    const NextApp = require("./Root").default;
    render(NextApp);
  });
}
