import { icon } from "../../icon-component";

export const FILTER_TYPE = {
  SIMPLE_LIST: "SIMPLE_LIST",
  TREE: "TREE",
  BOOLEAN: "BOOLEAN",
  TEXT_FIELD: "TEXT_FIELD",
};

const VERSION_ITEMS = {
  NEW: {
    label: "common:filters.select-filter.menus.new-content",
    iconName: icon.faPlusCircle,
    color: "var(--color-blue)",
    description: "common:filters.select-filter.menus.new-content.description",
    key: "NEW",
  },
  MAJOR: {
    label: "common:filters.select-filter.menus.major-modifications",
    iconName: icon.faExclamationTriangle,
    color: "var(--color-red)",
    description: "common:filters.select-filter.menus.major-modifications.description",
    key: "MODIFIED",
  },
  MINOR: {
    label: "common:filters.select-filter.menus.minor-modifications",
    iconName: icon.faExclamationTriangle,
    color: "var(--color-keppel)",
    description: "common:filters.select-filter.menus.minor-modifications.description",
    key: "MODIFIED_MINOR",
  },
  IDENTICAL: {
    label: "common:filters.select-filter.menus.identical-content",
    description: "common:filters.select-filter.menus.identical-content.description",
    key: "IDENTICAL",
  },
};

const REQUIREMENT_ITEMS = {
  EXPLICIT: {
    label: "common:filters.select-filter.menus.explicit",
    description: "common:filters.select-filter.menus.explicit-description",
    key: "isClientRequirement",
  },
  IMPLICIT: {
    label: "common:filters.select-filter.menus.implicit",
    description: "common:filters.select-filter.menus.implicit-description",
    key: "isRequirement",
  },
};

export default {
  VERSION: {
    iconName: icon.faQuestionCircle,
    filterKey: "infoversion",
    label: "common:filters.select-filter.menus.info-version",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
    items: VERSION_ITEMS,
  },
  LINKS: {
    iconName: icon.faLink,
    filterKey: "links",
    label: "common:filters.select-filter.menus.link-types",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
  },
  CRITICALITY: {
    filterKey: "reqCriticality",
    iconName: icon.faExclamationTriangle,
    label: "common:filters.select-filter.menus.req-criticality",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
  },
  NEGOTIABILITY: {
    filterKey: "reqNegotiability",
    iconName: icon.faHandshakeAngle,
    label: "common:filters.select-filter.menus.req-negotiability",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
  },
  TYPE: {
    filterKey: "reqType",
    iconName: icon.faLayerGroup,
    label: "common:filters.select-filter.menus.req-type",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
  },
  PROJECT: {
    filterKey: "projects",
    iconName: icon.faHammer,
    label: "navigation:common.projects",
    hasClearSelection: true,
    hasSearch: true,
  },
  REQUIREMENT: {
    filterKey: "requirement",
    iconName: icon.faFlag,
    label: "common:filters.requirement.title",
    description: "common:component.filters.helper.only-items-with-results",
    type: FILTER_TYPE.SIMPLE_LIST,
    items: REQUIREMENT_ITEMS,
  },
  DOCUMENTS: {
    filterKey: "documents",
    iconName: icon.faFileLines,
    label: "common:filters.select-filter.menus.document",
    description: "common:component.filters.helper.only-items-with-results",
    multiSelection: true,
    hasIsSeparator: true,
    hasSearch: true,
    labelSearch: "Role",
  },
  CATEGORIES: {
    filterKey: "categories",
    iconName: icon.faTag,
    label: "common:categories",
    multiSelection: true,
    hasIsSeparator: true,
    hasSearch: true,
    hasAndOrOperator: true,
  },
  TOC: {
    filterKey: "tocFilters",
    iconName: icon.faSitemap,
    label: "common:filters.select-filter.menus.document.toc",
    dynamicLabel: "common:filters.keywords.label-active.toc",
    disabledOptionTooltip: "common:filters.keywords.disable-negative-filters.toc.at-least-one-positive",
  },
  KEYWORDS: {
    filterKey: "keywordFilters",
    iconName: icon.faAlignCenter,
    label: "common:filters.select-filter.menus.content.paragraph",
    dynamicLabel: "common:filters.keywords.label-active.paragraph",
    disabledOptionTooltip: "common:filters.keywords.disable-negative-filters.paragraph.at-least-one-positive",
  },
  INFORMATION_ID: {
    filterKey: "informationIds",
    iconName: icon.faHashtag,
    label: "common:filters.select-filter.menus.content.information",
    hasIsSeparator: true,
  },
  POSITIONS: {
    filterKey: "positionsFilter",
    iconName: icon.faCheck,
    label: "common:filters.select-filter.menus.positions",
    hasClearSelection: true,
  },
  USERS: {
    filterKey: "users",
    label: "common:filters3.users.label",
    iconName: icon.faUser,
    multiSelection: true,
    hasSearch: true,
    hasClearSelection: true,
  },
  TAGS: {
    filterKey: "tags",
    label: "common:filters3.tags.label",
    iconName: icon.faTag,
    multiSelection: true,
    hasSearch: true,
    hasClearSelection: true,
  },
  CLIENT_REF: {
    filterKey: "clientRef",
    iconName: icon.faHashtag,
    label: "common:filters.select-filter.menus.req-details.client-ref",
    type: FILTER_TYPE.TEXT_FIELD,
  },
  SEARCH_CARD: {
    filterKey: "searchCard",
    label: "common:filters3.search-cards.label",
    multiSelection: true,
    hasSearch: true,
  },
  ALLOCATE: {
    filterKey: "isNotAllocated",
    label: "common:enum.coverage-status.not-allocated",
    type: FILTER_TYPE.BOOLEAN,
  },
  STAKE_HOLDING: {
    filterKey: "stakeHolding",
    label: "dashboards.task-dashboard.my-tasks",
    type: FILTER_TYPE.BOOLEAN,
  },
  OWNING: {
    filterKey: "owning",
    label: "dashboards.task-dashboard.supervising",
    type: FILTER_TYPE.BOOLEAN,
  },
  TO_BE_TESTED: {
    filterKey: "isToBeTested",
    label: "common:enum.coverage-status.to-be-tested",
    type: FILTER_TYPE.BOOLEAN,
  },
  EXCLUDED: {
    filterKey: "isExcluded",
    trueLabel: "common:enum.coverage-status.excluded",
    falseLabel: "common:enum.coverage-status.to-be-covered",
    type: FILTER_TYPE.BOOLEAN,
  },
  GRANULARITY: {
    filterKey: "granularity",
    label: "multi-projects-search.search.filter.granularity",
    type: FILTER_TYPE.SIMPLE_LIST,
    multiSelection: true,
  },
};
