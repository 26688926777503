import React, { useEffect, useMemo, useState } from "react";
import { DropDown, MenuElement } from "../../../common/components";
import { MenuList } from "@mui/material";
import { isNonEmptyArray, parseJwt } from "../../../common/utils";
import styles from "./Menus.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { KeycloakService } from "../../../sevices";
import { NavUtils } from "../../../navigation";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../api";
import { setFirstTimeLogin, setLanguage, setOpenProjectSelectionSidePanel } from "../../../common/slice";
import { translate } from "../../../common/providers";

const Menus = React.forwardRef(({ onClose, open }, ref) => {
  const language = useSelector(({ context }) => context.language);
  const companies = useSelector(({ context }) => context.companies);
  const projects = useSelector(({ context }) => context.projects);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [openedSubMenu, setOpenedSubMenu] = useState("");
  const [hasSingleInstance, setHasSingleInstance] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setHasSingleInstance(parseJwt(KeycloakService.getToken()).groups.length === 1);
  }, []);
  /// Menu and it's logic
  const handleOpenMenu = (subMenu) => {
    setOpenedSubMenu(subMenu);
  };
  const handleCloseSubMenu = () => {
    setOpenedSubMenu("");
  };
  const handleClose = () => {
    handleCloseSubMenu();
    onClose();
  };
  const selectLanguage = (value) => {
    const cancelToken = ApiService.getCancelTokenSource();
    setTimeout(() => {
      dispatch(setLanguage({ value, cancelToken }));
      dispatch(setFirstTimeLogin(false));
    }, 300);
  };
  const handleChangeLanguage = (value) => {
    if (language.value === value) {
      handleClose();
      return;
    }
    selectLanguage(value);
    handleClose();
  };

  const handleChangeBU = () => {
    history.push(NavUtils.gotoCompanySelection());
    handleClose();
  };
  const handleChangeProject = () => {
    dispatch(setOpenProjectSelectionSidePanel(true));
  };
  const handleChangeInstance = () => {
    history.push(NavUtils.gotoHome(true));
    handleClose();
  };
  const handleLogout = () => {
    KeycloakService.doLogout();
    handleClose();
  };
  const menus = useMemo(
    () => [
      {
        value: "language",
        title: translate("common:user-bar.change-language"),
        action: () => handleOpenMenu("language"),
        subMenus: [
          { value: "en", title: "English", action: () => handleChangeLanguage("en") },
          { value: "esp", title: "Español", action: () => handleChangeLanguage("esp") },
          { value: "fr", title: "Français", action: () => handleChangeLanguage("fr") },
        ],
      },
      {
        title: translate("common:user-bar.change-instance"),
        hidden: hasSingleInstance,
        action: handleChangeInstance,
      },
      { title: translate("common:user-bar.change-company"), hidden: companies?.length <= 1, action: handleChangeBU },
      {
        title: translate("common:user-bar.change-project"),
        hidden: projects?.length <= 1 || !projectId,
        action: handleChangeProject,
      },
      { title: translate("common:user-bar.logout"), action: handleLogout },
    ],
    [hasSingleInstance, companies, projects, projectId]
  );
  ///
  return (
    <DropDown ref={ref} open={open} onClose={handleClose}>
      <MenuList className={styles.menuContainer}>
        {isNonEmptyArray(menus) &&
          menus
            .filter((menu) => !menu.hidden)
            .map((menu) => (
              <MenuElement
                key={menu.title}
                menu={menu}
                openedSubMenu={openedSubMenu}
                onCloseSubMenu={handleCloseSubMenu}
              />
            ))}
      </MenuList>
    </DropDown>
  );
});

export default Menus;
