import { isNonEmptyArray, isNonEmptyObject } from "../../../utils";

export const generateFilters = (newFilters) => {
  const {
    infoversion,
    categories,
    reqCriticality,
    reqNegotiability,
    reqType,
    requirement,
    clientRef,
    documents,
    ...rest
  } = newFilters;
  const newPayload = {};
  if ((requirement || []).includes("isRequirement")) {
    newPayload.isRequirement = true;
  }
  if ((requirement || []).includes("isClientRequirement")) {
    newPayload.isClientRequirement = true;
  }
  if (reqCriticality) {
    newPayload.reqCriticality = { is: true, matchAll: true, elements: reqCriticality };
  }
  if (reqNegotiability) {
    newPayload.reqNegotiability = { is: true, matchAll: true, elements: reqNegotiability };
  }
  if (reqType) {
    newPayload.reqType = { is: true, matchAll: true, elements: reqType };
  }
  if (infoversion) {
    newPayload.versionStatus = infoversion;
  }
  if (isNonEmptyArray(documents?.elements)) {
    newPayload.documents = documents;
  }
  if (categories?.FAVORITE?.length > 0) {
    newPayload.favoriteCategoryIds = categories.FAVORITE;
  }
  if (clientRef?.length > 0) {
    newPayload.clientRef = clientRef;
  }
  if (categories?.CATEGORY?.length > 0) {
    newPayload.categories = {
      is: categories.is === undefined ? true : categories.is,
      matchAll: categories.matchAll === undefined ? false : categories.matchAll,
      elements: categories.CATEGORY,
    };
  }
  return {
    ...newPayload,
    ...rest,
  };
};
export const adjustFiltersFromPayload = (newFilters) => {
  const {
    isRequirement,
    isClientRequirement,
    reqCriticality,
    reqNegotiability,
    reqType,
    versionStatus,
    favoriteCategoryIds,
    categories,
    ...rest
  } = newFilters;
  const newPayload = {};
  if (isRequirement) {
    newPayload.requirement = ["isRequirement"];
  }
  if (isClientRequirement) {
    if (Array.isArray(newPayload.requirement)) {
      newPayload.requirement = [...newPayload.requirement, "isClientRequirement"];
    } else {
      newPayload.requirement = ["isClientRequirement"];
    }
  }
  if (reqCriticality) {
    newPayload.reqCriticality = reqCriticality.elements;
  }
  if (reqNegotiability) {
    newPayload.reqNegotiability = reqNegotiability.elements;
  }
  if (reqType) {
    newPayload.reqType = reqType.elements;
  }
  if (versionStatus) {
    newPayload.infoversion = versionStatus;
  }
  if (favoriteCategoryIds) {
    newPayload.categories.FAVORITE = favoriteCategoryIds;
    if (!isNonEmptyArray(categories?.elements)) {
      newPayload.categories = { matchAll: categories.matchAll, is: categories.is, CATEGORY: [] };
    }
  }
  if (categories?.elements?.length > 0) {
    newPayload.categories = { matchAll: categories.matchAll, is: categories.is, CATEGORY: categories.elements };
    if (!isNonEmptyArray(favoriteCategoryIds)) {
      newPayload.categories.FAVORITE = [];
    }
  }
  return {
    ...newPayload,
    ...rest,
  };
};
export const cleanFilters = (newFilters) => {
  const { categories, ...rest } = newFilters;
  if (isNonEmptyObject(categories)) {
    if (!Object.values(categories).some((cat) => isNonEmptyArray(cat))) {
      return { ...rest };
    }
  }
  return newFilters;
};
