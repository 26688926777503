import React, { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { ApiService } from "../api";
import { getFlags, getSegFlags, getUser } from "../common/slice";
import styles from "./UserBar.module.css";
import { aitendersLogo } from "../common/resources/images";
import { CustomIconButton, icon } from "../common/components";
import { KeycloakService } from "../sevices";
import { Menus } from "./components";
import { translate } from "../common/providers";

const UserBar = () => {
  const company = useSelector(({ context }) => context.company);
  const user = useSelector(({ context }) => context.user);
  const project = useSelector(({ context }) => context.project);
  const domain = useSelector(({ context }) => context.domain);
  const isLogged = KeycloakService.isLoggedIn();
  const [menuOpened, setMenuOpened] = useState(false);
  const cancelTokenSourceRef = useRef(null);
  const anchorRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, []);
  useEffect(() => {
    if (isLogged && !user && domain) {
      setTimeout(() => {
        batch(() => {
          dispatch(getFlags(cancelTokenSourceRef.current.token));
          dispatch(getUser(cancelTokenSourceRef.current.token));
        });
      }, 300);
    }
  }, [isLogged, domain]);
  useEffect(() => {
    const cancelToken = ApiService.getCancelTokenSource();
    if (project?.id && isLogged && company?.id) {
      dispatch(getSegFlags({ projectId: project?.id, token: cancelToken.token }));
    }
    return () => {
      ApiService.cancelTokens(cancelToken);
    };
  }, [project, company]);
  const handleMenuExpand = () => {
    setMenuOpened(true);
  };
  const handleMenuClose = () => {
    setMenuOpened(false);
  };
  return (
    <div className={styles.container}>
      <img alt="Aitenders_Logo" className={styles.logo} height="20px" src={aitendersLogo} />
      <div>
        <span className={styles.projectName}>{project?.name}</span>
        <CustomIconButton
          ref={anchorRef}
          className={styles.button}
          color="secondary"
          icon={icon.faGear}
          size="sm"
          tooltip={translate("common:btn.parameters")}
          variant="outlined"
          onClick={handleMenuExpand}
        />

        <Menus ref={anchorRef} open={menuOpened} onClose={handleMenuClose} />
      </div>
    </div>
  );
};

export default UserBar;
