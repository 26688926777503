import React, { useMemo } from "react";
import { Radio } from "@mui/material";
import MultiInput from "../multi-input/MultiInput";
import { translate } from "../../../../../../providers";
import { HorizontalDivider, CustomTooltip, CustomSplitButtonMenu } from "../../../../..";
import styles from "./OptionContainer.module.css";
import { OPERATORS } from "../../../../../../constants";

const OptionContainer = ({
  disabled = false,
  disabledOptionTooltip,
  globalSeparator,
  label = "",
  matchAll,
  onAddNew,
  onChangeInput,
  onChangeMatchAll,
  onChangeSelectedOption,
  onRemove,
  operator,
  selected = false,
  textInputs,
}) => {
  const options = useMemo(
    () => [
      { label: translate("common:component.filters.match-criteria.any"), value: "any" },
      { label: translate("common:component.filters.match-criteria.all"), value: "all" },
    ],
    []
  );
  const handleChangeSelectedOption = () => {
    if (!disabled) {
      onChangeSelectedOption(operator.name);
    }
  };
  const handleTooltip = () => {
    if (globalSeparator === OPERATORS.OR) {
      return translate("common:filters.keywords.disable-negative-filters.toc.no-or-global");
    }
    return translate(disabledOptionTooltip);
  };
  return (
    <CustomTooltip arrow placement="right" title={disabled ? handleTooltip() : ""}>
      <div
        className={styles.container}
        data-selected={selected}
        role="presentation"
        onClick={handleChangeSelectedOption}
      >
        <div>
          <Radio checked={selected} disabled={disabled} onChange={handleChangeSelectedOption} />
          <span className={styles.label} data-disabled={disabled}>
            {label}
          </span>
        </div>
        {selected && textInputs.length > 1 && (
          <div className={styles.matchAll__container}>
            <span className={styles.matchAll__helperText}>
              {translate("common:component.filters.match-criteria.match")}
            </span>
            <CustomSplitButtonMenu
              color="secondary"
              options={options}
              tooltip={translate("common:component.filters.match-criteria.tooltip")}
              value={matchAll ? "all" : "any"}
              onSelect={onChangeMatchAll}
            />
            <span className={styles.matchAll__helperText}>
              {translate("common:component.filters.match-criteria.criteria")}
            </span>
            <HorizontalDivider />
          </div>
        )}
        {selected &&
          Array.isArray(textInputs) &&
          textInputs.map((textInput, index) => (
            <MultiInput
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              input={textInput}
              isLast={textInputs.length - 1 === index}
              onAddNew={onAddNew}
              onChangeInput={onChangeInput}
              onRemove={onRemove}
            />
          ))}
      </div>
    </CustomTooltip>
  );
};

export default OptionContainer;
