import { arrayBufferToBase64, downloadFile } from "./function-utils";

/* global Word, Office */
class WordUtils {
  async insertTextToWord(text) {
    Word.run(async (context) => {
      context.document.getSelection().insertText(text);
    });
  }

  async insertSelectionToPlugin(onCallback) {
    await Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, (result) => {
      if (result.status == Office.AsyncResultStatus.Failed) {
        console.error("Action failed. Error: " + result.error.message);
      } else {
        onCallback(result);
      }
    });
  }

  async openDocumentInNewFile({ data, filename }) {
    if (filename.includes(".docx")) {
      Word.run(function (context) {
        var newDoc = context.application.createDocument(arrayBufferToBase64(data));
        context.load(newDoc);
        return context
          .sync()
          .then(() => {
            newDoc.open();
            context.sync();
          })
          .catch(console.error);
      }).catch(console.error);
    }
    if (filename.includes(".pdf")) {
      Office.onReady(function (info) {
        if (info.host === Office.HostType.Word) {
          if (Office.context.platform === Office.PlatformType.PC) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.Mac) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.OfficeOnline) {
            const blob = new Blob([data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            URL.revokeObjectURL(url);
          }
        }
      });
    }
  }
}
export default new WordUtils();
