import React, { useEffect, useState } from "react";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../common/providers";
import {
  CommonFilters,
  DocumentFilters,
  FiltersWrapper,
  InformationIdFilter,
  KeywordFilter,
  ProjectCategoriesFilter,
  generateFilters,
} from "../../../../common/components";
import styles from "./Search.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setFilters, setProjectFilters } from "../../slice/multi-project-search-slice";
import { RequirementMatrixService, SearchCardService } from "../../../../api";
import { useApi } from "../../../../common/hooks";
import { SEARCH_TABS } from "../../constants/tabs";

const granularityValues = ["chapter", "content"];

const Search = () => {
  const { call: getParametersForInformation } = useApi(RequirementMatrixService.getParametersForInformation);
  const { call: countResults } = useApi(SearchCardService.countResults);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const filters = useSelector(({ multiProjectSearch }) => multiProjectSearch.filters);
  const projectFilters = useSelector(({ multiProjectSearch }) => multiProjectSearch.projectFilters);
  const projectId = useSelector(({ context }) => context.project?.id);
  useSelector(({ context }) => context.segFlags);
  const [parameters, setParameters] = useState({});
  const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS)) {
      getParametersForInformation()
        .then(setParameters)
        .catch((err) => {
          console.error(err);
        });
    }
  }, [getParametersForInformation]);
  useEffect(() => {
    if (projectId) {
      setFiltersPreviewRequest(() => (previewFilters) => {
        const newFilters = generateFilters(previewFilters);
        return countResults({ projectId }, newFilters).then((data) => data);
      });
    }
  }, [projectId]);

  const handleApplyProjectFilters = (payload) => {
    dispatch(setProjectFilters(payload));
  };
  const handleApplyFilters = (payload) => {
    dispatch(setFilters(payload));
  };
  const customLabelApply = (results) => {
    if (results <= 1 && results !== null) {
      return translate("common:component.filter.apply", { results });
    }
    if (results > 1) {
      return translate("common:component.filters.apply", { results });
    }
    return translate("common:btn.apply");
  };

  return (
    <div className={styles.container}>
      {selectedSearchTab === SEARCH_TABS.PROJECT ? (
        <FiltersWrapper
          hasLargeSearch
          hasSeparator
          addFilterButtonVariant="contained"
          className={styles.filter}
          components={[
            {
              enabled: true,
              component: CommonFilters.KEYWORDS,
              renderer: <KeywordFilter />,
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
              withDescription: false,
              multiSelection: true,
              labelKey: "label",
              valueKey: "key",
              component: CommonFilters.REQUIREMENT,
            },
            { enabled: true, component: CommonFilters.INFORMATION_ID, renderer: <InformationIdFilter /> },
            {
              enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
              component: CommonFilters.DOCUMENTS,
              renderer: <DocumentFilters />,
              withDescription: false,
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
              component: CommonFilters.TOC,
              renderer: <KeywordFilter />,
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
              component: CommonFilters.CATEGORIES,
              renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
              hasFavorites: true,
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
              component: CommonFilters.CLIENT_REF,
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
              component: CommonFilters.CRITICALITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.criticality?.map((c) => ({
                label: `common:enum.params.criticality.${c.toLowerCase()}`,
                value: c,
              })),
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
              component: CommonFilters.NEGOTIABILITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.negotiability?.map((n) => ({
                label: `common:enum.params.negotiability.${n.toLowerCase()}`,
                value: n,
              })),
            },
            {
              enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
              component: CommonFilters.TYPE,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: parameters.type?.map((t) => ({
                label: `common:enum.params.type.${t.toLowerCase()}`,
                value: t,
              })),
            },
          ]}
          customLabelApply={customLabelApply}
          defaultFilters={projectFilters}
          disablePortal={false}
          filterPopUpPlacement="auto"
          previewRequest={filtersPreviewRequest}
          onApply={handleApplyProjectFilters}
        />
      ) : (
        <FiltersWrapper
          hasLargeSearch
          className={styles.filter}
          components={[
            {
              enabled: true,
              default: true,
              component: CommonFilters.GRANULARITY,
              labelKey: "label",
              valueKey: "value",
              dynamicItems: granularityValues?.map((t) => ({
                label: `multi-projects-search.search.filter.${t.toLowerCase()}`,
                value: t,
              })),
            },
          ]}
          defaultFilters={filters}
          onApply={handleApplyFilters}
        />
      )}
    </div>
  );
};

export default Search;
