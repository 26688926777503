import {
  faAnglesLeft,
  faAlignCenter,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBookmark,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleMinus,
  faDownload,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faGear,
  faHammer,
  faHandshakeAngle,
  faHashtag,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLock,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMinus,
  faMinusSquare,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faShieldHalved,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTag,
  faTimes,
  faUser,
  faUserTie,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faClock as faClockRegular } from "@fortawesome/free-regular-svg-icons";
export default {
  faAnglesLeft,
  faAlignCenter,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBan,
  faBookmark,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faClockRegular,
  faCircleMinus,
  faDownload,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faGear,
  faHammer,
  faHandshakeAngle,
  faHashtag,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLock,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMinus,
  faMinusSquare,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faShieldHalved,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTag,
  faTimes,
  faUser,
  faUserTie,
  faXmark,
};
