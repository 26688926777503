/* global Word, Office */
class WordUtils {
  async insertTextToWord(text) {
    Word.run(async (context) => {
      context.document.getSelection().insertText(text);
    });
  }

  async insertSelectionToPlugin(onCallback) {
    await Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, (result) => {
      if (result.status == Office.AsyncResultStatus.Failed) {
        console.error("Action failed. Error: " + result.error.message);
      } else {
        onCallback(result);
      }
    });
  }
}
export default new WordUtils();
