import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isNonEmptyArray } from "../../../../common/utils";
import IdentifiedContentBox from "../identified-content-box/IdentifiedContentBox";
import { ChapterPreview } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import styles from "./SelectionPreview.module.css";

const SelectionPreview = ({ contextAllowed = false, disableRemove = false }) => {
  const projectId = useSelector(({ context }) => context.project?.id);
  const savedContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedContent);
  const savedProjectContent = useSelector(({ multiProjectSearch }) => multiProjectSearch.savedProjectContent);
  const [chapterPreview, setChapterPreview] = useState(null);
  const savedContentsSpecificProject = useMemo(() => {
    if (projectId) {
      return savedProjectContent?.find((spc) => spc.projectId === projectId)?.content || [];
    }
    return [];
  }, [projectId, savedProjectContent]);

  const handleDisplaySelection = (data) => {
    setChapterPreview(data);
  };
  const handleCloseChapterDisplay = () => setChapterPreview(null);
  return (
    <div>
      {isNonEmptyArray(savedContent) && (
        <>
          <div className={styles.header}>{translate("common:selection-preview.inspiration")}</div>
          {savedContent.map((sc) => (
            <IdentifiedContentBox
              key={sc.id}
              disableRemove={disableRemove}
              identifiedContent={sc}
              onDisplaySelection={handleDisplaySelection}
            />
          ))}
        </>
      )}
      {contextAllowed && isNonEmptyArray(savedContentsSpecificProject) && (
        <>
          <div className={styles.header}>{translate("common:selection-preview.context")}</div>
          {savedContentsSpecificProject.map((sc) => (
            <IdentifiedContentBox
              key={sc.id}
              projectContentDeletion
              disableRemove={disableRemove}
              identifiedContent={sc}
              onDisplaySelection={handleDisplaySelection}
            />
          ))}
        </>
      )}
      <ChapterPreview
        coordinates={chapterPreview?.coordinates}
        docId={chapterPreview?.documentId}
        infoId={chapterPreview?.informationId}
        numOfPages={
          isNonEmptyArray(chapterPreview?.recievedRectangles)
            ? chapterPreview.recievedRectangles.length
            : chapterPreview?.numOfPages
        }
        open={!!chapterPreview}
        page={
          isNonEmptyArray(chapterPreview?.recievedRectangles)
            ? chapterPreview.recievedRectangles[0].pageNumber
            : chapterPreview?.page
        }
        projectId={chapterPreview?.projectId}
        recievedRectangles={chapterPreview?.recievedRectangles}
        onClose={handleCloseChapterDisplay}
      />
    </div>
  );
};

export default SelectionPreview;
